import React, { useEffect, useRef } from 'react';
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
    setCurrentAssessment,
    setCurrentSubmission,
    setSaving,
    setIsSubmissionUpdating,
    setCurrentMarking
} from '../actions/mark';
import { setNotification } from "../../actions/global";
import { DIAGNOSTIC_ASSESSMENT } from "../queries/diagnosticAssessmentForMarkingQuery";
import DELETE_SENTENCE from '../queries/deleteSentenceMutation';
import MERGE_SENTENCE from '../queries/mergeSentenceMutation';
import SubmissionMarker from "../components/SubmissionMarker/SubmissionMarker";
import Loading from "../../components/Loading";
import { isMarkedOrReviewed } from "../includes/marking-flow/utils";
import { GET_NEXT_MARKING, GET_PREV_MARKING } from "../queries/diagnosticMarkingsQuery";
import { MODE_MARK_ONE, MODE_MARKING, MODE_REVIEW_ONE, MODE_REVIEWING } from "../includes/marking-flow/constants";

const Supervisor = ({ user }) => {
    const navigate = useNavigate();
    const { assessmentId } = useParams();
    const dispatch = useDispatch();

    const {
        currentAssessment,
        currentMarking,
        currentSubmission,
        saving,
        markingMode,
    } = useSelector(state => state.mark);

    const { selectedMarker } = useSelector(state => state.review);

    const [saveMarking] = useMutation(SAVE_MARKING);
    const [deleteSentence] = useMutation(DELETE_SENTENCE);
    const [mergeSentence] = useMutation(MERGE_SENTENCE);

    const {
        data: assessmentData,
        loading: assessmentLoading,
        errors: assessmentErrors
    } = useQuery(
        DIAGNOSTIC_ASSESSMENT,
        {
            variables: { assessmentId },
            fetchPolicy: 'network-only',
        });

    const assessment = assessmentData?.diagnosticAssessment;

    const heartbeatIntervalRef = useRef(null);

    useEffect(function updateAssessment() {
        if (assessment) {
            dispatch(setCurrentAssessment({ ...assessment }));
        }

        if (assessmentErrors) {
            dispatch(setNotification(assessmentErrors.graphQLErrors[0].message, 'error'))
        }
    }, [assessment, assessmentErrors]);

    const [getNextMarking, { loading: nextMarkingLoading }] = useLazyQuery(
        GET_NEXT_MARKING,
        // 'Loading' status of useLazyQuery doesn't change without this.
        { notifyOnNetworkStatusChange: true }
    );

    const [getPrevMarking, { loading: prevMarkingLoading }] = useLazyQuery(
        GET_PREV_MARKING,
        { notifyOnNetworkStatusChange: true }
    );

    useEffect(
        () => {
            document.title = 'Diagnostics Review';

            if (currentAssessment && currentAssessment.size) {
                document.title = currentAssessment.staffName;
            }

            if (currentAssessment && currentMarking) {
                document.title = currentMarking.student?.Surname +
                    ', ' +
                    currentMarking.student?.FirstName +
                    ' :: ' +
                    currentAssessment.staffName;
            }
        },
        [currentAssessment, currentMarking]
    );

    useEffect(function initiateHeartbeatCycle() {
        const onHeartbeat = (markingId) => {
            saveMarking({
                mutation: SAVE_MARKING,
                variables: {
                    markingId,
                    input: { isActive: true },
                }
            })
        }

        if (currentMarking) {
            onHeartbeat(currentMarking.id);

            // 1 beat every minute.
            heartbeatIntervalRef.current = setInterval(({ markingId }) => {
                onHeartbeat(markingId);
            }, 60000, { markingId: currentMarking.id });

            return () => {
                clearInterval(heartbeatIntervalRef.current);
            }
        }
    }, [currentMarking]);

    useEffect(function fallbackRedirect() {
        if (!currentMarking || !currentSubmission) {
            navigate('/');
        }
    }, [currentMarking, currentSubmission]);

    const saveReview = ({ data, advanceMarkingStatus = false }) => {
        dispatch(setSaving(true));

        const input = { analysisData: JSON.stringify(data) };

        if (advanceMarkingStatus) {
            if (markingMode === MODE_MARK_ONE || markingMode === MODE_MARKING) {
                input.isMarked = true;
            }

            if (markingMode === MODE_REVIEW_ONE || markingMode === MODE_REVIEWING) {
                input.isReviewed = true;
            }
        }

        return saveMarking({
            mutation: SAVE_MARKING,
            variables: {
                markingId: currentMarking.id,
                input,
            }
        }).catch(errorData => {
            if (errorData.graphQLErrors) {
                dispatch(setNotification(errorData.graphQLErrors[0].message, 'error'))
            }
        }).finally(() => {
            dispatch(setSaving(false));
            dispatch(setIsSubmissionUpdating(false));
        });
    }

    /**
     * Handle save action for marking.
     *
     * @param data
     */
    const onReviewSave = (data) => {
        const sub = { ...data };

        sub.ModifiedByReviewer = isMarkedOrReviewed(currentSubmission);

        saveReview({ data: sub });
    }

    const onExit = (data, advanceMarkingStatus = false) => {
        saveReview({ data, advanceMarkingStatus }).then(() => {
            navigate(-1);
        });
    }

    /**
     * Handle next action while marking (save current and go to next Submission).
     *
     * @param data
     */
    const onReviewNext = (data) => {
        saveReview({ data, advanceMarkingStatus: true }).then(() => {
            getNextMarking({
                variables: {
                    assessmentId,
                    markingMode,
                    ...selectedMarker && { markerId: selectedMarker.id }
                },
                // This query should not be fetched from cache.
                fetchPolicy: 'network-only',
            }).then(({ data: { getNextMarking } }) => {
                if (getNextMarking === null) {
                    return navigate(-1);
                }

                dispatch(setCurrentMarking({ ...getNextMarking }));
            }).catch(errorData => {
                if (errorData.graphQLErrors) {
                    dispatch(setNotification(errorData.graphQLErrors[0].message, 'error'));
                }
            });
        });
    }

    const onDeleteSentence = (sentence) => {
        dispatch(setNotification('Deleting...', 'info'));
        return deleteSentence({
            variables: {
                data: JSON.stringify(sentence)
            }
        }).then(({ data }) => {
            dispatch(setCurrentSubmission(data.deleteDiagnosticsSentence));
            dispatch(setNotification('Sentence Deleted', 'success'));
            return data;
        }).catch(errorData => {
            if (errorData.graphQLErrors) {
                dispatch(setNotification(errorData.graphQLErrors[0].message, 'error'))
            }
        });
    }

    const onMergeSentence = (mergeData) => {
        dispatch(setNotification('Merging...', 'info'));
        return mergeSentence({
            variables: {
                data: JSON.stringify(mergeData)
            }
        }).then(({ data }) => {
            dispatch(setCurrentSubmission(data.mergeDiagnosticsSentence));
            dispatch(setNotification('Sentences Merged', 'success'));
            return data;
        }).catch(errorData => {
            if (errorData.graphQLErrors) {
                dispatch(setNotification(errorData.graphQLErrors[0].message, 'error'));
            }
        });
    }

    const onReviewBack = () => {
        getPrevMarking({
            variables: {
                assessmentId,
                currentMarkingId: currentMarking.id,
                markingMode,
                ...selectedMarker && { markerId: selectedMarker.id }
            },
            // This query should not be fetched from cache.
            fetchPolicy: 'network-only',
        }).then(({ data: { getPrevMarking } }) => {
            if (getPrevMarking === null) {
                return navigate(-1);
            }

            dispatch(setCurrentMarking({ ...getPrevMarking }));
        }).catch(errorData => {
            if (errorData.graphQLErrors) {
                dispatch(setNotification(errorData.graphQLErrors[0].message, 'error'));
            }
        });
    };

    if (assessmentLoading || nextMarkingLoading || prevMarkingLoading) {
        return <Loading />
    }

    return (
        <SubmissionMarker
            assessment={currentAssessment}
            onBack={onReviewBack}
            onSave={onReviewSave}
            onExit={onExit}
            onNext={onReviewNext}
            saving={saving}
            submission={currentSubmission}
            onDeleteSentence={onDeleteSentence}
            onMergeSentence={onMergeSentence}
            user={user}
            isMarking={!isMarkedOrReviewed(currentSubmission)}
            currentTimeMarking={currentSubmission.LengthOfMarkingTime || 0}
        />
    );
}

const SAVE_MARKING = gql`
    mutation updateDiagnosticMarking($markingId: ID!, $input: MarkingInput) {
        updateDiagnosticMarking(markingId: $markingId, input: $input) {
            id
        }
    }
`;

export default Supervisor;


