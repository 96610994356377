export const actionTypes = {
    UPDATE_SELECTED_ROWS: 'UPDATE_SELECTED_ROWS',
    UPDATE_SORT_DIRECTION: 'UPDATE_SORT_DIRECTION',
    UPDATE_SORT_FIELD: 'UPDATE_SORT_FIELD',
    UPDATE_SEARCHED_FIELDS: 'UPDATE_SEARCHED_FIELDS',
    UPDATE_SELECTED_MARKER: 'UPDATE_SELECTED_MARKER',
};

export function updateSelectedRows(selectedRows) {
    return {
        type: actionTypes.UPDATE_SELECTED_ROWS,
        selectedRows,
    };
}

export function updateSortDirection(sortDirection) {
    return {
        type: actionTypes.UPDATE_SORT_DIRECTION,
        sortDirection,
    };
}

export function updateSortField(sortField) {
    return {
        type: actionTypes.UPDATE_SORT_FIELD,
        sortField,
    };
}

export function updateSearchedFields(searchedFields) {
    return {
        type: actionTypes.UPDATE_SEARCHED_FIELDS,
        searchedFields,
    };
}

export function updateSelectedMarker(selectedMarker) {
    return {
        type: actionTypes.UPDATE_SELECTED_MARKER,
        selectedMarker,
    }
}
