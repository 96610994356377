export default class LoginError extends Error {
    format = 'text';

    link = '';

    constructor(message, format, link) {
        super(message);
        this.format = format;
        this.link = link;
    }

    /**
     * Set the format for the error message. Text or html.
     *
     * @param format
     * @returns {LoginError}
     */
    setFormat(format) {
        this.format = format;
        return this;
    }

    /**
     * Get the format for the message.
     *
     * @returns {string}
     */
    getFormat() {
        return this.format;
    }

    /**
     * Set the link for the error, this is an alternative link to login.
     *
     * @param link
     */
    setLink(link) {
        this.link = link;
    }

    /**
     * Get the link for the error, this is an alternative link to login.
     *
     * @returns {string}
     */
    getLink() {
        return this.link;
    }
}
