import { actionTypes } from './actions';

const defaultState = {};

export default function form(state = defaultState, action) {
    switch (action.type) {
    case actionTypes.FORM_CLEAR: {
        const newState = Object.assign({}, state);

        if (typeof newState[action.formName] !== 'undefined') {
            delete newState[action.formName];
        }

        return newState;
    }

    case actionTypes.FORM_UPDATE_FIELD: {
        // make sure form fields have been initialised
        const formFields = typeof state[action.formName] !== 'undefined' ? state[action.formName] : {};

        return Object.assign({}, state, {
            [action.formName]: Object.assign({}, formFields, {
                [action.fieldName]: action.fieldValue,
            }),
        });
    }

    default: return state;
    }
}
