import React, {useMemo} from "react";
import { Route, Routes } from "react-router-dom";
import { useSelector } from 'react-redux';

import User from '../../includes/User';

// components
import Assessments from './Assessments';
import Notification from '../../components/Notification';
import Submissions from './Submissions';
import Supervisor from './Supervisor';
import ReviewMarkers from '../components/ReviewMarkers';

const Mark = () => {
    const { username, scopes } = useSelector(state => state.auth);

    const user = useMemo(
        () => {
            return new User(username, scopes);
        },
        [username, scopes]
    );
    return (
        <div className="md-grid md-grid--no-spacing marker__container">
            <Notification/>
            <div className="md-cell md-cell--12 marker__grid">
                <Routes>
                    <Route
                        path="/"
                        element={<Assessments user={user} />}
                        exact
                        default
                    />

                    <Route
                        path="/assessment/:assessmentId"
                        element={<Submissions user={user} />}
                        exact
                    />

                    <Route
                        path="/review/:assessmentId"
                        element={<Supervisor user={user} />}
                        exact
                    />

                    <Route
                        path="/review-markers"
                        element={<ReviewMarkers user={user} />}
                        exact
                    />

                </Routes>
            </div>
        </div>
    );
};

export default Mark;