import React from 'react';
import PropTypes from 'prop-types';

const CLASSNAME = 'marker__metric';

const SubmissionMarkerSentenceMetrics = ({ onSentenceMetricChange, paragraph, sentence, sentenceMetrics }) => {
    /**
     * Formats the value to be human readable.
     *
     * @param value
     * @returns {*}
     */
    const formatValue = (value) => {
        switch (typeof value) {
        case 'boolean': return value ? 1 : 0;
        default: return value;
        }
    }

    /**
     * Checks the metric first and doesn't do anything if it's `Capitalised` metric and its value is already 1.
     * @param metric
     * @param paragraph
     * @param sentence
     */
    const onClick = (metric, paragraph, sentence) => {
        const value = sentence[metric.field];

        if (metric.label === "Capitalised" && value >= 1) {
            return;
        }

        onSentenceMetricChange(
            metric.field,
            paragraph,
            sentence,
            value + 1,
            metric.accumulator
        )
    };

    /**
     * Checks the metric value first and doesn't do anything if it is already 0.
     * @param event
     * @param metric
     * @param paragraph
     * @param sentence
     */
    const onContentMenuClick = (event, metric, paragraph, sentence) => {
        event.preventDefault();

        const value = sentence[metric.field];

        if (value <= 0) {
            return;
        }

        onSentenceMetricChange(
            metric.field,
            paragraph,
            sentence,
            value - 1,
            metric.accumulator
        );
    };

    return (
        <div className="marker__metrics">
            {sentenceMetrics.map(metric => {
                if (!metric.label) {
                    return null;
                }

                let style;

                if (metric.label === 'Capitalised' || metric.label === 'Txt Speak') {
                    style = { marginRight: '30px' };
                }

                return (
                    <span
                        key={'SentenceMetric_' + metric.field + '_' + sentence.id}
                        className={CLASSNAME + ' ' + CLASSNAME + '--' + metric.field.toLowerCase()}
                        onClick={() => onClick(metric, paragraph, sentence)}
                        onContextMenu={(e) => onContentMenuClick(e, metric, paragraph, sentence)}
                        title={metric.label}
                        { ...style && { style }}
                    >
                        {formatValue(sentence[metric.field])}
                    </span>
                )
            })}
        </div>
    );
}

SubmissionMarkerSentenceMetrics.propTypes = {
    onSentenceMetricChange: PropTypes.func,
    paragraph: PropTypes.object,
    sentence: PropTypes.object,
    sentenceMetrics: PropTypes.array
};

export default SubmissionMarkerSentenceMetrics;
