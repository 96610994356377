import { gql } from '@apollo/client';

const FullSentenceFragment = gql`
    fragment FullSentenceFragment on analysedSentence {
        id
        SAOrder
        Text
        ProperNouns
        ProperNounErrors
        PunctuationErrors
        PunctuationSpaceErrors
        Slang
        Specifics
        TxtSpeak
        Tokens
        SpellingErrors
        SAW
        SASpell
        SATxt
        SASlang
        SASBan
        SASpec
        SACap
        SAPNE
        SAProper
        SAPunc
        SASConn
        SASS
    }`;

export default FullSentenceFragment;
