import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const COLOURS = {
    "spelling-error": "red",
    "proper-noun": "blue",
    "txt-speak": "orange",
    "slang": "#000000",
    "punctuation-error": "#dbdb00",
    "proper-noun-error": "pink",
    "specific": "darkgreen",
};

const SubmissionMarkerWord = ({
    className = 'marker__word',
    isActive,
    onContextMenu,
    onDoubleClick,
    parentKey,
    text,
    types = [],
}) => {
    /**
     * Get the css class name if we have a current type.
     *
     * @param className
     * @param types
     * @param isActive
     * @returns {*}
     */
    const getClassName = (className, types, isActive) => {
        const original = className;

        if (types.length > 0) {
            types.map(type => {
                className += ' ' + original + '--' + type;
            })

            className += ' ' + original + '--selected';
        }

        if (types.length > 2) {
            className += ' ' + original + '--multi-error';
        }

        // the word is "active" - menu selection is active
        if (isActive) {
            className += ' ' + original + '--active';
        }

        return className;
    }

    /**
     * Get list of error colours
     *
     * @param types
     * @returns {*[]}
     */
    const getErrorColours = (types) => {
        const keys = Object.keys(COLOURS);
        const values = Object.values(COLOURS);
        const colours = [];

        for (let i = 0; i < keys.length; i++) {
            if (types.includes(keys[i])) {
                colours.push(values[i]);
            }
        }

        return colours;
    }

    /**
     * Get the background colour based on the className.
     * 
     * @param colours
     * @returns {string|*}
     */
    const getBackground = (colours) => {
        if (colours.length > 0) {
            const degree = 100 / colours.length;
            let gradient = 'linear-gradient(to bottom,';

            colours.map((colour, index) => {
                if (index > 0) gradient += ', ';
                gradient += ' ' + colour + ' ' + degree * index + '%, ';
                gradient += ' ' + colour + ' ' + degree * (index + 1) + '%';
            });

            return gradient + ')';
        }

        return "";
    }

    /**
     * Get Styles
     *
     * @param colours
     * @returns {{background: (string|*)}}
     */
    const getStyle = (colours) => {
        const style = {};

        if (colours.length <= 2) {
            style.background = getBackground(colours);
        } else {
            style.position = 'relative';
            style.border = '1px solid #c5c5c5';
            style.color = 'black';
        }

        return style;
    }

    /**
     * Best to Memoise these two as this component is rendered en masse.
     * @type {*[]}
     */
    const errorColours = useMemo(
        () => {
            return getErrorColours(types);
        },
        [types]
    );

    const fullClassName = useMemo(
        () => {
            return getClassName(className, types, isActive);
        },
        [className, types, isActive]
    );

    return (
        <span
            className={fullClassName}
            style={getStyle(errorColours)}
            key={parentKey + '_word'}
            onContextMenu={onContextMenu}
            onDoubleClick={onDoubleClick}
        >
            {text}
            {types.length > 2 && (
                <span className={className + '-multi-error-container'} style={{width: 16 * types.length}}>
                    {errorColours.map((colour, index) => (
                        <span
                            className={className + '-multi-error-box'}
                            style={{backgroundColor: colour}}
                            key={`multi-error-box-${index}`}
                        />
                    ))}
                </span>
            )}
        </span>
    );
}

SubmissionMarkerWord.propTypes = {
    className: PropTypes.string,
    isActive: PropTypes.bool,
    onContextMenu: PropTypes.func.isRequired,
    onDoubleClick: PropTypes.func.isRequired,
    offset: PropTypes.number,
    parentKey: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    types: PropTypes.array,
};

export default SubmissionMarkerWord;