import React from 'react';

/**
 * Apply label class to an existing css class.
 *
 * @param className
 * @returns {string}
 */
export const applyLabelToClassName = (className) => {
    return className + ' ' + className + '--with-label'
}

/**
 * Get the css classes for the field container.
 * @param containerClass
 * @param label
 * @returns {string}
 */
export const getContainerClass = (containerClass = 'form-input', label) => {
    let containerClassName = containerClass;

    if (label) {
        containerClassName = applyLabelToClassName(containerClassName);
    }

    return containerClassName;
}

/**
 * Get the css classes for the field. Modifiers (BEM) can be used to create additional classes.
 *
 * @param modifiers
 * @param fieldClass
 * @param label
 * @param additionalModifiers
 * @returns {string}
 *
 */
export const getFieldClass = (modifiers = [], fieldClass, label, additionalModifiers = []) => {
    let fieldClassName = fieldClass;
    const allModifiers = modifiers.concat(additionalModifiers);

    if (label) {
        fieldClassName = applyLabelToClassName(fieldClassName);
    }

    if (allModifiers.length) {
        allModifiers.forEach(modifier => fieldClassName += ' ' + fieldClass + '--' + modifier);
    }

    return fieldClassName;
}

/**
 * Get the css class for the label. Creates labels based on both container and field.
 *
 * @returns {string}
 */
export const getLabelClass = (containerClass, fieldClass, labelClass) => {
    return labelClass || (containerClass + '__label' + ' ' + fieldClass + '__label');
}

/**
 * Get the label element.
 *
 * @returns {null}
 */
export const getLabel = (label, name, containerClass, fieldClass, labelClass) => {
    return label ? <label htmlFor={name} className={getLabelClass(containerClass, fieldClass, labelClass)}>{label}</label> : null;
}

export const defaultProps = {
    containerClass: 'form-input',
};

export default {
    applyLabelToClassName,
    getContainerClass,
    getFieldClass,
    getLabelClass,
    getLabel,
    defaultProps,
};