import React, { Component } from 'react';

class ErrorBoundary extends Component {
    state = {
        error: '',
        errorInfo: '',
        hasError: false,
    };

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        // eslint-disable-next-line no-console
        this.setState({ error, errorInfo, hasError: true });
    }

    render() {
        const { error, errorInfo, hasError } = this.state;
        const { children } = this.props;

        return !hasError ? children : (
            <div
                style={{
                    border: '1px solid red',
                    padding: '20px',
                    margin: '100px auto 0 auto',
                    width: '1000px',
                    maxWidth: '80%'
                }}
            >
                <h3>{error.message}</h3>
                {errorInfo && (
                    <ul>
                        {errorInfo.componentStack.split('in').map((line, index) => line.trim() && (
                            <li key={`errorInfo-${index}`}>in {line}</li>
                        ))}
                    </ul>
                )}
            </div>
        )
    }
}

export default ErrorBoundary;