import React, {useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import TableHeader from './TableHeader';
import TableSearchHeader from './TableSearchHeader';

const Table = ({
    defaultSortField = '',
    defaultSortDirection = '',
    defaultSearchedFields = {},
    fields = {},
    cellClassName = '',
    extraRowClassName = '',
    footer = [],
    headerCellClassName = '',
    list = [],
    onRowClick = () => {},
    selectedRowClassName = 'table-row--selected',
    tableClassName = '',
    searchable = false,
    onSort = () => {},
    onSearch = () => {},
    extraFields = {},
    doFilter = () => {},
    tableFilter = {},
    onRowSelected = () => {},
    isRowSelectedHandler = () => {},
}) => {
    const [state, setState] = useState({
        sortField: defaultSortField || '',
        sortDirection: defaultSortDirection || 'asc',
        isSearchShowing: false,
        searchedFields: defaultSearchedFields || {},
    });

    const sort = (field) => {
        // return if sort not configured for this field
        if (!fields[field].sort) {
            return;
        }

        let sortDirection = state.sortDirection;
        const previousField = state.sortField;

        sortDirection = field !== previousField ?
            //sort on new field defaults to asc
            'asc' :
            //switch sort direction if sorting the same field
            ((sortDirection === 'asc') ? 'desc' : 'asc');

        onSort(field, sortDirection);

        setState({
            ...state,
            sortDirection,
            sortField: field
        });
    }

    const getSortedList = () => {
        if (!state.sortField) return list;

        return list.slice(0).sort((a, b) => {
            //check if column has a custom value

            const aValue = fields[state.sortField].value ?
                fields[state.sortField].value(a) :
                a[state.sortField];

            const bValue = fields[state.sortField].value ?
                fields[state.sortField].value(b) :
                b[state.sortField];

            // null values are considered less than what they are being compared to
            if (state.sortDirection === 'asc') {
                if (aValue > bValue || bValue === null) return 1;
                if (aValue < bValue || aValue === null) return -1;
                return 0;
            } else {
                if (aValue > bValue || bValue === null) return -1;
                if (aValue < bValue || aValue === null) return 1;
                return 0;
            }
        });
    }

    const getFilteredList = (list) => {
        if (!doFilter) {
            return list;
        }

        const keys = Object.keys(tableFilter);
        const values = Object.values(tableFilter);

        return list.filter((item) => {
            let check = true;
            values.map((value, index) => {
                if (value && check) {
                    check = item[keys[index]] > 0 || item[keys[index]];
                }
            });

            return check;
        })


    }

    const getSearchedList = (list) => {
        const keys = Object.keys(state.searchedFields);
        const values = Object.values(state.searchedFields);

        if (!keys.length) {
            return list;
        }

        list = list.filter(item => {
            let check = false;

            for (let i = 0; i < keys.length; i++) {
                check = String(fields[keys[i]].value(item).toString().toLowerCase()).includes(values[i].toLowerCase());

                // If it doesn't match one filter, then we don't include.
                if (!check) {
                    break;
                }
            }

            return check;
        });

        return list;
    }

    const getRefinedList = () => {
        let list = getSortedList();

        if (doFilter) {
            list = getFilteredList(list);
        }

        if (searchable) {
            list = getSearchedList(list);
        }

        return list;
    }

    /**
     * If tableFilter changes, check if we should manually run the getRefinedList function.
     */
    useEffect(
        () => {
            const keys = Object.keys(tableFilter);

            let shouldUpdate = false;

            keys.map(key => {
                if (tableFilter[key] !== tableFilter[key]) {
                    shouldUpdate = true;
                }
            })

            if (shouldUpdate) {
                getRefinedList();
            }
        },
        [tableFilter]
    );

    const onRowClicked = (row, index, e) => {
        e.preventDefault();
        if (onRowClick) onRowClick(
            row,
            // get previous and next items
            typeof list[index - 1] !== 'undefined' ? list[index - 1] : null,
            typeof list[index + 1] !== 'undefined' ? list[index + 1] : null,
        );
        onRowSelected && onRowSelected(row);
    }

    const renderTableCell = (fieldName, item, useExtraFields = false) => {
        const field = useExtraFields ? extraFields[fieldName] : fields[fieldName];

        let className = field.className || cellClassName || 'table-cell';
        className += field.extraFieldClassName ? ' ' + field.extraFieldClassName(item) : '';

        return <td
            className={className}
            key={'row_' + (item.ID || item.id) + '_' + fieldName}>
            {
                typeof(field.value) === 'function' ?
                    field.value(item) :
                    item[fieldName]
            }
        </td>;
    }

    const onSearchFields = (field, value) => {
        let searchedFields = {};

        if (typeof field !== 'undefined') {
            searchedFields = state.searchedFields;

            searchedFields[field] = value;
        }

        onSearch(searchedFields);

        setState({ ...state, searchedFields });
    }

    const onOpenSearch = () => {
        setState({ ...state, isSearching: !state.isSearching });
    }

    const refinedList = useMemo(
        () => getRefinedList(),
        [list, state]
    );

    return (
        <table className={tableClassName || 'table'}>
            <TableHeader
                containerClassName="table-header"
                rowClassName="table-row"
                cellClassName={headerCellClassName || "table-cell table-cell--header"}
                cellClick={sort}
                fields={fields}
                sortField={state.sortField}
                sortDirection={state.sortDirection}
                searchable={searchable}
                onOpenSearch={onOpenSearch}
                searchedFields={state.searchedFields}
                onClearSearch={() => onSearchFields()}
            />
            {searchable && state.isSearching && (
                <TableSearchHeader
                    containerClassName="table-search-header"
                    rowClassName="table-row table-row--short"
                    cellClassName={headerCellClassName || "table-cell table-cell--search-header"}
                    fields={fields}
                    onSearch={onSearchFields}
                    searchedFields={state.searchedFields}
                />
            )}
            <tbody className="md-table-body">
                {refinedList.map((item, index) => {
                    const defaultClassName = 'table-row';

                    let rowClassName = defaultClassName;

                    if (extraRowClassName && extraRowClassName(item).length > 0) {
                        rowClassName += ' ' + extraRowClassName(item);
                    }

                    rowClassName += " " + defaultClassName + ((index+1) % 2 === 0 ? "--even" : "--odd");

                    rowClassName += (isRowSelectedHandler && isRowSelectedHandler(item)) ? ' ' + selectedRowClassName : '';

                    return (
                        <React.Fragment key={'row_' + (item.ID || item.id)}>
                            <tr
                                onClick={(e) => onRowClicked(item, index, e)}
                                className={rowClassName}
                            >
                                {Object.keys(fields).map((fieldName) => renderTableCell(fieldName, item))}
                            </tr>
                        </React.Fragment>
                    )
                })}
            </tbody>
            {footer &&
                <tfoot>
                    <tr>
                        {footer.map((column, index) => <td key={`column-${index}`}>{column}</td>)}
                    </tr>
                </tfoot>
            }
        </table>
    );
}

Table.propTypes = {
    cellClassName: PropTypes.string,
    defaultSearchedFields: PropTypes.object,
    defaultSortDirection: PropTypes.string,
    defaultSortField: PropTypes.string,
    doFilter: PropTypes.func,
    extraFields: PropTypes.object,
    extraRowClassName: PropTypes.func,
    extraRowsField: PropTypes.string,
    fields: PropTypes.object.isRequired,
    footer: PropTypes.array,
    hasExtraRows: PropTypes.bool,
    headerCellClassName: PropTypes.string,
    isRowSelectedHandler: PropTypes.func,
    list: PropTypes.array.isRequired,
    onRowClick: PropTypes.func,
    onRowSelected: PropTypes.func,
    onSearch: PropTypes.func,
    onSort: PropTypes.func,
    searchable: PropTypes.bool,
    selectedRowClassName: PropTypes.string,
    tableClassName: PropTypes.string,
    tableFilter: PropTypes.object,
};

export default Table;