class ReviewActions {
    updateSelectedRows = (state, action) => (
        {
            ...state,
            selectedRows: action.selectedRows || [],
        }
    );

    updateSortDirection = (state, action) => (
        {
            ...state,
            sortDirection: action.sortDirection || 'asc',
        }
    );

    updateSortField = (state, action) => (
        {
            ...state,
            sortField: action.sortField || '',
        }
    );

    updateSearchedFields = (state, action) => (
        {
            ...state,
            searchedFields: action.searchedFields || {},
        }
    );

    updateSelectedMarker = (state, action) => (
        {
            ...state,
            selectedMarker: action.selectedMarker || null,
        }
    )

    /**
     * Handles the action, if no handler configured, return the state.
     *
     * @param state
     * @param action
     * @returns {*}
     */
    handleAction = (state, action) => {
        const newState = state;

        if (action.type) {
            const functionName = this.cameliseString(action.type);
            if (
                typeof this[functionName] === 'function'
            ) {
                return this[functionName](newState, action);
            }
        }

        return newState;
    }

    /**
     * Turn a snake case name into lower camelcase.
     *
     * @param snakeCase
     */
    cameliseString = (snakeCase) => {
        const parts = snakeCase.split('_');

        return parts.reduce((camel, part, index) => (index === 0
        // just lowercase first word
            ? camel + part.toLowerCase()
        // other words lowercase and make first char uppercase
            : camel + part.charAt(0).toUpperCase() + part.toLowerCase().slice(1)),
        '');
    }
}

const defaultState = {
    selectedRows: {},
    sortField: '',
    sortDirection: 'asc',
    searchedFields: {},
    selectedMarker: null,
};

const reviewActions = new ReviewActions();

export default function review(state = defaultState, action) {
    // check for default loaded state and convert to immutable
    return reviewActions.handleAction(state, action);
}
