import omit from 'lodash/omit';
import { actionTypes } from '../actions/mark';
import config from '../../config/config';

const defaultState = {
    currentAssessment: {},
    // This refers to the current 'analysed piece' object.
    currentSubmission: null,
    // This refers to the current 'written answer' object.
    currentMarking: null,
    saving: false,
    isSubmissionUpdating: false,
    markingMode: '',
};

export function mark(prevState = defaultState, action) {
    const state = { ...prevState };
    const currentSubmission = { ...state.currentSubmission };

    switch (action.type) {
    case actionTypes.ADD_WORD: {
        const newSenData = {
            ...state.currentSubmission
                .paragraphs[action.paragraph.id]
                .sentences[action.sentence.id],
        };

        newSenData[action.field]++;
        newSenData[action.list][action.index.toString()] = action.word;

        state.currentSubmission
            .paragraphs[action.paragraph.id]
            .sentences[action.sentence.id] = newSenData;

        return {
            ...state,
            currentSubmission: {
                ...state.currentSubmission,
                [action.accumulator]: Object.values(state.currentSubmission.paragraphs)
                    // add up paragraph values
                    .reduce((pValue, paragraph) => pValue + Object.values(paragraph.sentences)
                        // add up sentence values for each paragraph
                        .reduce((sValue, sentence) => sValue + sentence[action.field], 0), 0),
            },
        };
    }

    case actionTypes.REMOVE_WORD: {
        const newSenData = {
            ...state.currentSubmission
                .paragraphs[action.paragraph.id]
                .sentences[action.sentence.id],
        };

        newSenData[action.field]--;
        delete newSenData[action.list][action.index.toString()];

        state.currentSubmission
            .paragraphs[action.paragraph.id]
            .sentences[action.sentence.id] = newSenData;

        return {
            ...state,
            currentSubmission: {
                ...state.currentSubmission,
                [action.accumulator]: Object.values(state.currentSubmission.paragraphs)
                    // add up paragraph values
                    .reduce((pValue, paragraph) => pValue + Object.values(paragraph.sentences)
                        // add up sentence values for each paragraph
                        .reduce((sValue, sentence) => sValue + sentence[action.field], 0), 0),
            },
        };
    }

    case actionTypes.SET_CURRENT_SUBMISSION: {
        return {
            ...state,
            currentSubmission: setSubmission(action, action.submission),
        };
    }

    case actionTypes.UPDATE_PARAGRAPH_METRIC: {
        const newState = { ...state };

        newState
            .currentSubmission
            .paragraphs[action.paragraph.id][action.metric] = action.value;

        return { ...newState };
    }

    case actionTypes.UPDATE_SENTENCE_METRIC: {
        let newState = { ...state };

        newState
            .currentSubmission
            .paragraphs[action.paragraph.id]
            .sentences[action.sentence.id][action.metric] = action.value;

        // if sentence type, update the counts
        if (action.metric === 'SASS') {
            newState = updateSentenceTypes(newState);
        }

        return {
            ...newState,
            currentSubmission: {
                ...newState.currentSubmission,
                [action.accumulator]: Object.values(newState.currentSubmission.paragraphs)
                    // add up paragraph values
                    .reduce((pValue, paragraph) => pValue + Object.values(paragraph.sentences)
                        // add up sentence values for each paragraph
                        .reduce((sValue, sentence) => sValue + sentence[action.metric], 0), 0),
            },
        };
    }

    case actionTypes.SET_CURRENT_MARKING: {
        if (!action.marking) {
            return {
                ...state,
                currentMarking: null,
                currentSubmission: null,
            };
        }

        return {
            ...state,
            currentMarking: {
                ...(omit(action.marking, ['submission'])),
            },
            currentSubmission: setSubmission(action, action.marking?.submission || null),
        };
    }

    case actionTypes.SET_CURRENT_ASSESSMENT:
        return { ...state, currentAssessment: action.assessment };

    case actionTypes.SET_SAVING:
        return { ...state, saving: action.saving };

    case actionTypes.UPDATE_SUBMISSION_METRIC:
        currentSubmission[action.metric] = action.value;
        return { ...state, lastSave: action.time, currentSubmission };

    case actionTypes.SET_IS_SUBMISSION_UPDATING:
        return { ...state, isSubmissionUpdating: action.isSubmissionUpdating };

    case actionTypes.SET_MARKING_MODE:
        return { ...state, markingMode: action.markingMode };

    default:
        return state;
    }
}

/**
 * Updates the unique sentence styles and most common style.
 *
 * @param currState
 * @returns {*}
 */
function updateSentenceTypes(currState) {
    const state = currState;
    const styles = {};

    Object.keys(config.sentenceTypes).forEach((style) => {
        styles[style] = 0;
    });

    // count sentence styles
    Object.values(state.currentSubmission.paragraphs)
        .map(paragraph => Object.values(paragraph.sentences)
            .forEach((sentence) => {
                if (sentence.SASS) {
                    // increment by one or initialise
                    styles[sentence.SASS] = Object.prototype
                        .hasOwnProperty.call(styles, sentence.SASS)
                        ? styles[sentence.SASS] + 1
                        : 1;
                }
            }));

    const uniqueStyles = [];

    // calculate unique styles
    Object.keys(styles).forEach(style => (styles[style] > 0 ? uniqueStyles.push(style) : null));

    Object.keys(styles).forEach((style) => {
        state.currentSubmission[style] = styles[style];
    });

    // top sentence style
    state.currentSubmission.TopSS = uniqueStyles.reduce((top, current) => (
    // don't count incompletes
        current !== 'SSI' && (!top || styles[current] > styles[top]) ? current : top));

    state.currentSubmission.IncSS = Object.prototype.hasOwnProperty.call(styles, 'SSI') ? styles.SSI : 0;

    return state;
}

/**
 * Converts the old format of an array of words for word lists to a map with the word
 * index as the key.
 *
 * @param {object} sentence
 * @param {array}  list
 * @returns {{}}
 */
function convertWordListToIndexedMap(sentence, list) {
    const returnObj = {};

    list.forEach((word) => {
        // if number, then we have the index of a space
        if (Number.isInteger(word)) {
            returnObj[word] = ' ';
        } else { // lookup the first occurrence of this word in the sentence
            sentence.Tokens.some((token) => {
                if (word === token.originalText) {
                    returnObj[token.characterOffsetBegin] = word;
                    return true;
                }

                return false;
            });
        }
    });

    return returnObj;
}

function setSubmission(action, submission) {
    // submission being reset
    if (submission === null) {
        return null;
    }

    const newSubmission = { ...submission };

    const newParas = {};

    // cleanup paragraphs (loading from graphql)
    newSubmission.paragraphs.forEach((paraNode) => {
        // need to "clone" as some instances it isn't extensible
        const paragraph = { ...paraNode };
        const newSens = {};
        // cleanup sentences
        paragraph.sentences.forEach((senNode) => {
            // need to "clone" as some instances it isn't extensible
            const sentence = { ...senNode };
            // get the tokens
            sentence.Tokens = sentence.Tokens ? JSON.parse(sentence.Tokens) : [];

            // setup word type lists, need to import JSON
            config.sentenceMetrics.forEach((wordType) => {
                // don't add if not a list
                if (wordType.list) {
                    // initialise to empty object if we don't have anything
                    const list = sentence[wordType.list]
                        ? JSON.parse(sentence[wordType.list])
                        : {};

                    // check if we have an array (old format), and convert to a map with index
                    sentence[wordType.list] = Array.isArray(list)
                        ? convertWordListToIndexedMap(sentence, list)
                        : list;

                    // fix old format, move spaces from PunctuationErrors
                    if (wordType.list === 'PunctuationSpaceErrors'
                        && Object.keys(sentence.PunctuationErrors).length) {
                        Object.keys(sentence.PunctuationErrors).forEach((key) => {
                            if (sentence.PunctuationErrors[key] === ' ') {
                                delete sentence.PunctuationErrors[key];
                                sentence.PunctuationSpaceErrors[key] = ' ';
                            }
                        });
                    }
                }
            });
            newSens[sentence.id] = sentence;
            // paragraph.sentences[sentence.id] = sentence;
        });
        paragraph.sentences = newSens;
        newParas[paragraph.id] = paragraph;
        // submission.paragraphs[paragraph.id] = paragraph;
    });

    newSubmission.paragraphs = newParas;

    return newSubmission;
}

export default mark;
