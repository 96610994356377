export const AUTH_STORAGE_KEY = 'authToken';
export const AUTH_BEARER_PREFIX = 'Bearer ';
export const AUTH_HEADER = 'Authorization';

/**
 * Number of seconds before expiry to refresh the token.
 *
 * @type {number}
 */
export const AUTH_REFRESH_LEEWAY = 60 * 2;
