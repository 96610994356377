import React from 'react';
import { defaultProps, getContainerClass, getFieldClass, getLabel } from '../config';

const TextField = ({ containerClass, fieldClass, fieldRef, label, labelClass, name, type, value, ...otherProps }) => (
    <div className={getContainerClass(containerClass, label)}>
        {getLabel(label, name, containerClass, fieldClass, labelClass)}
        <input
            name={name}
            id={name}
            type={type}
            value={value || value === 0 || value === '0' ? value : ''}
            className={getFieldClass([type], fieldClass, label)}
            {...otherProps}
            ref={fieldRef} />
    </div>
);

// TextField.defaultProps = Object.assign({}, FormField.defaultProps, {
//     fieldClass: 'text-field',
//     type: 'text'
// });

TextField.defaultProps = {
    ...defaultProps,
    fieldClass: 'text-field',
    type: 'text',
};

export default TextField;