export const actionTypes = {
    UPDATE_FIELD: 'UPDATE_FIELD',
};

export const updateField = (formName, fieldName, value) => ({
    type: actionTypes.UPDATE_FIELD,
    formName,
    fieldName,
    value,
});
