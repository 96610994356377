import { gql } from '@apollo/client';
import FullSubmissionFragment from './FullSubmissionFragment';
import FullParagraphFragment from './FullParagraphFragment';
import FullSentenceFragment from './FullSentenceFragment';

const deleteSentenceMutation = gql`
    mutation deleteDiagnosticsSentence($data: String) {
        deleteDiagnosticsSentence (data: $data) {
            ...FullSubmissionFragment

            paragraphs {
                ...FullParagraphFragment

                sentences {
                    ...FullSentenceFragment
                }
            }
        }
    }
    
    ${FullSubmissionFragment}
    ${FullParagraphFragment}
    ${FullSentenceFragment}
    
`;


export default deleteSentenceMutation;
