import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { clearForm, updateField } from './actions';

const Form = ({
    onFormChange,
    disableStore,
    name,
    onSubmit,
    values,
    children,
    onFieldChange,
}) => {
    const fieldRefs = useRef({});
    const dispatch = useDispatch();
    const formFields = useSelector(state => state.formFields);

    const fields = useMemo(
        () => {
            if (formFields) {
                return typeof formFields[name] !== 'undefined' ? formFields[name] : {}
            }
        },
        [formFields, name]
    );

    useEffect(
        () => {
            if (onFormChange) {
                onFormChange(fields);
            }
        },
        [onFormChange, fields]
    );

    useEffect(
        () => {
            if (disableStore) {
                return dispatch(clearForm(name));
            }
        },
        [dispatch, disableStore, name]
    );

    /**
     * Gets the value from the field ref (DOM element).
     *
     * @param fieldName
     * @returns {null}
     */
    const getFieldRefValue = (fieldName) => {
        if (typeof fieldRefs.current[fieldName] !== 'undefined') {
            // handle different input types
            switch (fieldRefs.current[fieldName].type) {
            case 'checkbox':
                return fieldRefs.current[fieldName].checked;
            default:
                return fieldRefs.current[fieldName].value;
            }
        }

        return null;
    }

    /**
     * Get the value of an individual form field. If fromState is true it is loaded from props (via redux state).
     *
     * @param fieldName
     * @returns {*}
     */
    const getFieldValue = (fieldName) => {
        const value = getFieldRefValue(fieldName);

        // load from passed values if no store or no store values
        if (
            (disableStore || typeof fields === 'undefined' || Object.keys(fields).length === 0)
            &&
            (values && typeof values[fieldName] !== 'undefined')
        ) {
            return values[fieldName];
            // load from store
        } else if (typeof fields[fieldName] !== 'undefined') {
            return fields[fieldName];
            // default to field value
        } else {
            return value ? value : '';
        }
    }

    /**
     * Get all the values for the fields in the form from state.
     *
     * @returns {{}}
     */
    const getFieldValues = () => {
        const values = {};

        Object.keys(fieldRefs.current).map(fieldName => values[fieldName] = getFieldValue(fieldName));

        return values;
    }

    /**
     * Handle the form submit.
     *
     * @param e
     */
    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (onSubmit) {
            onSubmit(getFieldValues());
        }
    }

    /**
     * Handle the value of a field changing (store). Calls original onChange event if one existed.
     *
     * @param e
     * @param fieldName
     * @param originalOnChange
     */
    const onFieldChangeEvent = (e, fieldName, originalOnChange) => {
        if (!disableStore) {
            dispatch(updateField(name, fieldName, getFieldRefValue(fieldName)));
        }

        if (originalOnChange) {
            e.persist();
            originalOnChange.call(e);
        }

        // listener
        if (onFieldChange) {
            onFieldChange(fieldName, getFieldRefValue(fieldName));
        }
    }

    /**
     * Render an individual child element of the form, recursively renders children.
     *
     * @param {object} child
     */
    const renderChild = (child) => {
        // string inside a component, ignore
        if (typeof child === 'string') {
            return child;
        }

        let props = {
            children: renderChildren(child.props.children)
        };

        // add field ref and onchange if a form field
        if (typeof child.type === 'function') {
            props = Object.assign(props, {
                onChange: (e) => onFieldChangeEvent(e, child.props.name, child.props.onChange),
                value: getFieldValue(child.props.name),
                fieldRef: ref => {
                    fieldRefs.current[child.props.name] = ref;
                },
                key: name + '_' + child.props.name
            });
        }

        return React.cloneElement(child, props);
    }

    /**
     * Renders the child elements, cloning form field to alter props to work with the form.
     *
     * @returns {Object}
     */
    const renderChildren = (children) => {
        return React.Children.map(children, child => renderChild(child));
    }

    return (
        <form onSubmit={handleSubmit}>
            { renderChildren(children) }
        </form>
    );
}

Form.propTypes = {
    disableStore: PropTypes.bool,
    name: PropTypes.string,
    onSubmit: PropTypes.func,
    values: PropTypes.object,
};

export default Form;