import React from "react";
import PropTypes from 'prop-types';

import User from '../../includes/User';

//Queries
import DIAGNOSTIC_MARKERS from "../queries/diagnosticMarkersQuery";

//Components
import Table from '../../components/Table/Table';
import Button from '../../components/Button';
import { logout } from "../../actions/auth";
import Loading from '../../components/Loading';
import {useNavigate} from 'react-router-dom';
import {useQuery} from '@apollo/client';
import {useDispatch} from 'react-redux';

const ReviewMarkers = ({ user }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { data, loading } = useQuery(DIAGNOSTIC_MARKERS);

    const markers = data?.diagnosticMarkers;

    const goBack = () => navigate(-1);
    const doLogout = () => dispatch(logout());

    /**
     * Set row colour contingent upon CorrectionRate
     *
     * @param marker
     * @returns {string}
     */
    const checkCorrectionRate = (marker) => {
        if (marker.correctionRate >= 95) {
            return 'table-row--ok';
        } else if (marker.correctionRate >= 80) {
            return 'table-row--info';
        }
        else if (marker.correctionRate >= 1) {
            return 'table-row--error';
        }

        return '';
    }

    return (
        <div className="reviewMarkers">
            <div className="md-grid marker__actions">
                <div className="md-cell md-cell--6">
                    <p>Welcome {user.getUsername()}</p>
                </div>
                <div className="md-cell md-cell--6 marker__actions-right">
                    <Button color="green" onClick={() => goBack()}>
                        <i className="material-icons icon--reverse">forward</i> Back
                    </Button>

                    <Button color="green" onClick={doLogout}>Logout
                        <i className="material-icons">exit_to_app</i>
                    </Button>
                </div>
            </div>
            <div className="markersTable">
                {loading && (
                    <Loading />
                )}
                <Table
                    extraRowClassName={(m) => checkCorrectionRate(m)}
                    list={markers ? markers : []}
                    defaultSortField="CorrectionRate"
                    defaultSortDirection="desc"
                    fields={{
                        firstName: {
                            label: 'First Name',
                            sort: true,
                        },
                        surname: {
                            label: 'Last Name',
                            sort: true,
                        },
                        averageMarkingTime: {
                            label: 'Avg Time',
                            sort: true,
                        },
                        correctionRate: {
                            label: 'Accuracy',
                            sort: true,
                            value: (m) => m.correctionRate + "%"
                        },
                        markerAverageWords: {
                            label: 'Avg Length',
                            sort: true,
                        },
                        markedAllTime: {
                            label: 'Marked',
                            sort: true,
                        },
                        hoursSpent: {
                            label: 'Hours',
                            sort: true,
                            value: (m) => m.hoursSpentMarking
                        },
                    }}
                />
            </div>
        </div>
    );
}

ReviewMarkers.propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
};

export default ReviewMarkers;