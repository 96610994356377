import { actionTypes } from '../actions/global';

const defaultState = {
    message: '',
    messageType: '',
    offline: false,
};

const global = (state = defaultState, action) => {
    switch (action.type) {
    case actionTypes.SET_NOTIFICATION:
        return Object.assign({}, state, {
            message: action.message,
            messageType: action.messageType,
        });

    case actionTypes.SET_OFFLINE:
        return Object.assign({}, state, {
            offline: action.offline,
        });

    default:
        return state;
    }
};

export default global;
