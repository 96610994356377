/**
 * @param {object} sub
 * @return {boolean}
 */
import { ACTION_MARK, ACTION_REVIEW } from "./constants";

export const isMarkedOrReviewed = sub => sub?.MarkedAt || sub?.ReviewedAt;

/**
 * Get the next 'action' (marking/reviewing) string based on the status of a marking piece.
 *
 * @param {object} marking
 * @return {string}
 */
export const getNextAction = marking => {
    if (marking.status === 'Marking' || marking.status === 'Ready for marking') {
        return ACTION_MARK;
    }

    return ACTION_REVIEW;
}