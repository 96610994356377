import "babel-polyfill";
import "whatwg-fetch";
import React from "react";
import { configureStore } from "./Mark/store/configureStore";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import Mark from "./Mark/containers/Mark";
import { configureClient } from "./Mark/includes/apollo";
import { ApolloProvider } from "@apollo/client";
import LoggedInUser from "./components/LoggedInUser";
import ConnectionChecker from "./components/ConnectionChecker";
import ErrorBoundary from "./components/ErrorBoundary";

const store = configureStore({});
const client = configureClient(store);

const mark = () => (
    <Provider store={store}>
        <ApolloProvider client={client}>
            <ConnectionChecker>
                <LoggedInUser scopes={['Marker']} title=" to WTE Marking">
                    <HashRouter basename={""}>
                        <ErrorBoundary>
                            <Mark />
                        </ErrorBoundary>
                    </HashRouter>
                </LoggedInUser>
            </ConnectionChecker>
        </ApolloProvider>
    </Provider>
);

export default mark;
