import React from 'react';
import PropTypes from 'prop-types';

const SubmissionMarkerMultiWord = ({
    children,
    className = 'marker__multi-word',
    onDoubleClick,
    parentKey,
    type,
}) => {
    /**
     * Get the css class name if we have a current type.
     *
     * @param className
     * @param type
     * @returns {*}
     */
    const getClassName = (className, type) => {
        const original = className;

        if (type) {
            className += ' ' + original + '--' + type + ' ' + original + '--selected';
        }

        return className;
    }

    return (
        <span
            className={getClassName(className, type)}
            key={parentKey + '_multiword'}
            // on right click add specific
            onDoubleClick={onDoubleClick}
        >
            {children}
        </span>
    );
}

SubmissionMarkerMultiWord.propTypes = {
    className: PropTypes.string,
    onDoubleClick: PropTypes.func.isRequired,
    offset: PropTypes.number,
    parentKey: PropTypes.string.isRequired,
    type: PropTypes.string,
};

export default SubmissionMarkerMultiWord;