export const actionTypes = {
    SET_NOTIFICATION: 'SET_NOTIFICATION',
    SET_OFFLINE: 'SET_OFFLINE',
};

export function setNotification(message, messageType = 'error') {
    return {
        type: actionTypes.SET_NOTIFICATION,
        message,
        messageType,
    };
}

export const setOffline = isOffline => ({
    type: actionTypes.SET_OFFLINE,
    offline: isOffline,
});
