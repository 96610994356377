/**
 * Properly format a diagnostic test marker's full name.
 *
 * @param {object} marker
 * @return {string}
 */
export const getMarkerFormattedName = marker => {
    return `${marker?.firstName || ''} ${marker?.surname || ''}`.trim();
}

/**
 * Block unwanted key events.
 *
 * @param {Event} e
 * @param {array} stringArr
 * @return {void}
 */
export const blockUnwantedKeyEvt = (e, stringArr) => {
    stringArr.includes(e.key) && e.preventDefault();
}
