import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";

import Button from '../../../components/Button';
import Form from "../../../components/Form/Form";
import LabelField from "../../../components/Form/Fields/LabelField";
import TextField from "../../../components/Form/Fields/TextField";
import config from "../../../config/config";
import { setIsSubmissionUpdating, updateSubmissionMetric } from "../../actions/mark";
import { setNotification } from "../../../actions/global";
import SelectField from "../../../components/Form/Fields/SelectField";

/**
 * @param {object} props
 * @param {string} props.title
 * @param {object} props.date
 * @param {object} props.schoolName
 * @param {object} props.studentName
 * @param {object} props.pieceInstructions
 * @param {object} props.pieceText
 *
 * @returns {JSX.Element}
 */
const Details = ({
    title,
    date,
    schoolName,
    studentName,
    pieceInstructions,
    pieceText,
}) => {
    const [isPieceTextVisible, setIsPieceTextVisible] = useState(false);

    const {
        currentSubmission,
    } = useSelector(state => state.mark);

    const { paragraphSelectOptions } = config;

    const dispatch = useDispatch();

    /**
     * Update Submission with given metric.  Creates a Promise object and waits for the submission to be updated before
     * saving to server.
     * @param field
     * @param value
     */
    const onUpdateSubmissionMetric = (field, value) => {
        new Promise((resolve, reject) => {
            resolve(dispatch(updateSubmissionMetric(field, value)));
        }).then(() => {
            dispatch(setIsSubmissionUpdating(true));
        }).catch((error) => {
            dispatch(setNotification(error.message, 'error'));
        });
    }

    return (
        <div className="details">
            <div className="details-header">
                <span>{title}: {date}</span>
                <button
                    className="btn btn--gold"
                    onClick={() => setIsPieceTextVisible(_isPieceTextVisible => !_isPieceTextVisible)}
                >
                    {isPieceTextVisible ? "Hide" : "Show"} Whole Piece
                </button>
                {studentName} - {schoolName}
            </div>
            <div className={"md-grid md-grid--no-spacing details-content"}>
                <div
                    className={"md-cell md-cell--8 details-text"}    
                >
                    <div dangerouslySetInnerHTML={{ __html: pieceInstructions }} />
                    {isPieceTextVisible && (
                        <>
                            <hr />
                            <div className="details-piece-text" dangerouslySetInnerHTML={{ __html: pieceText }} />
                        </>
                    )}
                </div>
                <div className="md-cell md-cell--4 details-metrics">
                    <Form
                        disableStore={true}
                        onFieldChange={onUpdateSubmissionMetric}
                        name="PieceMetricsMarkerForm"
                        values={currentSubmission}
                    >
                        <div className="md-grid field-group">
                            <div className="md-cell md-cell--4">Metric Name</div>
                            <div className="md-cell md-cell--4">API</div>
                            <div className="md-cell md-cell--4">Human</div>

                            <div className="md-cell md-cell--4 md-cell--middle">
                                <LabelField name="CoherenceLabel" label="Coherence" />
                            </div>
                            <div className="md-cell md-cell--4">
                                <TextField name="OriginalCoherence" label="" disabled />
                            </div>
                            <div className="md-cell md-cell--4">
                                <SelectField
                                    name="Coherence"
                                    label=""
                                    listItems={paragraphSelectOptions}
                                />
                            </div>
                            <div className="md-cell md-cell--4 md-cell--middle">
                                <LabelField name="RepetitionLabel" label="Repetition" />
                            </div>
                            <div className="md-cell md-cell--4">
                                <TextField name="OriginalRepetition" label="" disabled />
                            </div>
                            <div className="md-cell md-cell--4">
                                <SelectField
                                    name="Repetition"
                                    label=""
                                    listItems={paragraphSelectOptions}
                                />
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    )
};

Details.propTypes = {
    title: PropTypes.string,
    date: PropTypes.string,
    schoolName: PropTypes.string,
    studentName: PropTypes.string,
    pieceInstructions: PropTypes.string,
    pieceText: PropTypes.string,
}

export default Details;
