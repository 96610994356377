import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from '../reducers/index';

const reducers = require('../reducers');

const STORE_NAME = 'markStore';

const persistentStorageMiddleware = store => next => (action) => {
    next(action);
    const state = store.getState();

    const json = {};

    if ('mark' in state) {
        json.mark = state?.mark;
    }

    if ('auth' in state) {
        json.auth = state?.auth;
    }

    if ('review' in state) {
        json.review = state?.review;
    }

    window.localStorage.setItem(STORE_NAME, JSON.stringify(json));
};

const enhancer = compose(
    applyMiddleware(thunk),
    applyMiddleware(persistentStorageMiddleware),
    window.devToolsExtension ? window.devToolsExtension() : f => f,
);

let preloadedState = {};

if (window.localStorage.getItem(STORE_NAME) !== null) {
    try {
        preloadedState = JSON.parse(window.localStorage.getItem(STORE_NAME));
    } catch (e) {
        // silently fail
    }
}

export const configureStore = () => {
    const store = createStore(
        rootReducer,
        preloadedState,
        enhancer,
    );

    if (module.hot) {
        module.hot.accept('../reducers', () => store.replaceReducers(reducers));
    }

    return store;
};

export default configureStore;
