import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { capitalize } from "lodash/string";
import Table from '../../components/Table/Table';
import { getNextAction } from "../includes/marking-flow/utils";
import { ACTION_MARK, ACTION_REVIEW, MODE_MARK_ONE, MODE_REVIEW_ONE } from "../includes/marking-flow/constants";
import { getMarkerFormattedName } from "../../includes/utils";

const SubmissionList = ({
    loading,
    submissions,
    onStart,
    onSort,
    onSearch,
    defaultSearchedFields,
    defaultSortField,
    defaultSortDirection,
}) => (
    <div className="submissions-list">
        {(!!submissions && !!submissions.length && !loading) &&
            <div className="submissions-list__table">
                <Table
                    list={submissions}
                    searchable={true}
                    defaultSortField={defaultSortField || 'student'}
                    defaultSortDirection={defaultSortDirection || 'asc'}
                    defaultSearchedFields={defaultSearchedFields || {}}
                    onSort={onSort}
                    onSearch={onSearch}
                    fields={{
                        student: {
                            label: 'Student',
                            sort: true,
                            value: (o) => o.student?.FirstName + ' ' + o.student?.Surname
                        },
                        details: {
                            label: 'Details',
                            sort: true,
                            value: (o) => 'Q: ' +  o.questionNumber + ' ' + o.questionLetter,
                        },
                        marker: {
                            label: 'Marker',
                            sort: true,
                            value: (o) => getMarkerFormattedName(o.marker) || 'N/A',
                        },
                        reviewer: {
                            label: 'Reviewer',
                            sort: true,
                            value: (o) => getMarkerFormattedName(o.reviewer) || 'N/A',
                        },
                        status: {
                            label: 'Status',
                            sort: true,
                            value: (o) => o.status,
                        },
                        yearLevel: {
                            label: submissions.every((sub, i, self) => (
                                sub.yearLevelLabel === self[0].yearLevelLabel
                            )) ? submissions[0].yearLevelLabel : "Year Level",
                            sort: true,
                            value: (o) => o.yearLevel || 'N/A',
                        },
                        actions: {
                            label: '',
                            className: 'table-cell table-cell--right',
                            value: (o) => {
                                const nextAction = getNextAction(o);

                                let mode = '';
                                let actionDisabled = false;
                                let tooltip = '';

                                switch (nextAction) {
                                case ACTION_MARK:
                                    mode = MODE_MARK_ONE;
                                    actionDisabled = !o.piece.IsMarkable;
                                    break;
                                case ACTION_REVIEW:
                                    mode = MODE_REVIEW_ONE;
                                    actionDisabled = !o.piece.IsReviewable
                                    break;
                                }

                                const onMarkOrReview = e => {
                                    onStart(e, { marking: o, mode })
                                }

                                if (actionDisabled) {
                                    tooltip = `This is already being actively ${nextAction}ed`
                                }

                                return (
                                    <div className="table-row-actions">
                                        <button
                                            onClick={onMarkOrReview}
                                            disabled={actionDisabled}
                                            title={tooltip}
                                        >
                                            {capitalize(nextAction)}
                                        </button>
                                    </div>
                                );
                            }
                        },
                    }}
                />
            </div>
        }

        {loading && <p>Loading...</p>}

        {!loading && (!submissions || !submissions.length) && <p>No submissions could be found.</p>}
    </div>
);

SubmissionList.propTypes = {
    loading: PropTypes.bool,
    submissions: PropTypes.array,
    onStart: PropTypes.func,
    onSort: PropTypes.func,
    onSearch: PropTypes.func,
    defaultSearchedFields: PropTypes.object,
    defaultSortField: PropTypes.string,
    defaultSortDirection: PropTypes.string,
};

export default SubmissionList;
