import { gql } from '@apollo/client';
import FullSubmissionFragment from './FullSubmissionFragment';
import FullParagraphFragment from './FullParagraphFragment';
import FullSentenceFragment from './FullSentenceFragment';

const mergeSentenceMutation = gql`
    mutation mergeDiagnosticsSentence($data: String) {
        mergeDiagnosticsSentence (data: $data) {
            ...FullSubmissionFragment

            paragraphs {
                ...FullParagraphFragment

                sentences {
                    ...FullSentenceFragment
                }
            }
        }
    }

    ${FullSubmissionFragment}
    ${FullParagraphFragment}
    ${FullSentenceFragment}

`;


export default mergeSentenceMutation;
