import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import User from '../../includes/User';
import { useDispatch } from "react-redux";
import { useLazyQuery, useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

// queries
import { READ_ASSESSMENTS } from "../queries/assessments";
import { GET_NEXT_MARKING } from "../queries/diagnosticMarkingsQuery";

// actions
import { logout } from '../../actions/auth'
import { setCurrentAssessment, setCurrentMarking, setCurrentSubmission, setMarkingMode } from '../actions/mark';
import { setNotification } from '../../actions/global';

// components
import AssessmentList from '../components/AssessmentList';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import { ACTION_MARK, MODE_MARKING, ACTION_REVIEW } from "../includes/marking-flow/constants";

const Assessments = ({ user }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [getNextMarking] = useLazyQuery(GET_NEXT_MARKING);

    const { data, loading } = useQuery(
        READ_ASSESSMENTS,
        {
            fetchPolicy: 'network-only',
        },
    );

    const assessments = data?.diagnosticAssessments;

    // Run on mount, reset marking data in state.
    useEffect(() => {
        dispatch(setCurrentAssessment({}));
        dispatch(setCurrentMarking(null));
        dispatch(setCurrentSubmission(null));
        dispatch(setMarkingMode(''));
    }, []);

    /**
     * Handle viewing assessments for an assessment.
     *
     * @param e
     * @param assessment
     */
    const onViewSubmissions = (e, assessment) => {
        e.stopPropagation();

        dispatch(setCurrentAssessment(assessment));

        navigate('/assessment/' + assessment.id);
    }

    /**
     * Begin marking/reviewing all relevant submissions from this test.
     *
     * @param e
     * @param {object} assessment
     * @param {string} markingMode
     */
    const onStartAssessment = (e, { assessment, markingMode }) => {
        e.stopPropagation();

        dispatch(setMarkingMode(markingMode));

        getNextMarking({
            variables: {
                assessmentId: assessment.id,
                markingMode,
            },
            // This query should not be fetched from cache.
            fetchPolicy: 'network-only',
        }).then(({ data: { getNextMarking } }) => {
            if (getNextMarking === null) {
                dispatch(setNotification(
                    `The assessment doesn't have anything to 
                    ${markingMode === MODE_MARKING ? ACTION_MARK : ACTION_REVIEW}`
                ));
            } else {
                dispatch(setCurrentMarking({ ...getNextMarking }));

                navigate('/review/' + assessment.id);
            }
        }).catch((errorData) => {
            if (errorData.graphQLErrors) {
                dispatch(setNotification(errorData.graphQLErrors[0].message));
            }
        });
    }

    const reviewMarkers = () => navigate('/review-markers');

    const doLogout = () => dispatch(logout());

    return (
        <div className="marker__assessments">
            <div className="md-grid marker__actions">
                <div className="md-cell md-cell--6">
                    <p>Welcome {user.getUsername()}</p>
                </div>
                <div className="md-cell md-cell--6 marker__actions-right">
                    <Button color="info" onClick={() => reviewMarkers()}>Review Markers
                        <i className="material-icons">assignment_ind</i>
                    </Button>
                    <Button color="green" onClick={doLogout}>Logout
                        <i className="material-icons">exit_to_app</i>
                    </Button>
                </div>
            </div>

            {loading ? (
                <Loading />
            ) : (
                <AssessmentList
                    assessments={assessments}
                    onStartAssessment={onStartAssessment}
                    onViewSubmissions={onViewSubmissions}
                />
            )}
        </div>
    );
}

Assessments.propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
};

export default Assessments;
