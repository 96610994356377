import React from 'react';
import PropTypes from 'prop-types';
import { capitalize } from "lodash/string";

import Button from '../../../components/Button';
import {
    MODE_ACTION_MAP,
    MODE_MARKING,
    MODE_REVIEWING
} from "../../includes/marking-flow/constants";
import { getMarkerFormattedName } from "../../../includes/utils";

const SubmissionMarkerActionBar = ({
    originalMarker,
    onBack,
    onExit,
    onExitAndAdvance,
    onNext,
    saving,
    timer,
    markingMode,
}) => {
    const hasNextPrev = markingMode === MODE_MARKING || markingMode === MODE_REVIEWING;

    const action = MODE_ACTION_MAP[markingMode];

    return (
        <div
            style={{
                display: "flex",
                justifyContent: 'space-between',
                padding: "8px",
                boxSizing: 'border-box',
            }}
            className="marker__actions"
        >
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                gap: "25px",
            }}>
                <div className="marker__actions__stopWatch">Marking time: {timer}</div>
                <div>Marker: {getMarkerFormattedName(originalMarker) || 'N/A'}</div>
                <div>Current action: {capitalize(action)}ing</div>
            </div>
            <div style={{ width: "200px" }} className="text--centered">
                {saving && <span className="marker__saving">Saving... <i className="material-icons status-bar__saving">save</i></span>}
            </div>
            <div className="marker__actions-right">
                <div className="marker--info"></div>
                {hasNextPrev && (
                    <Button color="error" onClick={onBack}>
                        <i className="material-icons icon--reverse">forward</i>
                        Previous Submission
                    </Button>
                )}
                <Button color="error" onClick={onExit}>Go Back</Button>
                <Button color="green" onClick={onExitAndAdvance}>Done and Go Back</Button>
                {hasNextPrev && (
                    <Button color="green" onClick={onNext}>
                        Done and {capitalize(action)} Next Submission
                        <i className="material-icons">forward</i>
                    </Button>
                )}
            </div>
        </div>
    )
};

SubmissionMarkerActionBar.propTypes = {
    originalMarker: PropTypes.object,
    onBack: PropTypes.func,
    onExit: PropTypes.func,
    onExitAndAdvance: PropTypes.func,
    onNext: PropTypes.func,
    saving: PropTypes.bool,
    timer: PropTypes.string,
    markingMode: PropTypes.string,
};

export default SubmissionMarkerActionBar;