import { gql } from '@apollo/client';

export const READ_ASSESSMENTS = gql`
    query diagnosticAssessments {
        diagnosticAssessments {
            id
            staffName
            numMarkableAnswers
            numMarkedAnswers
            numReviewedAnswers
            school {
                Name
            }
        }
    }
`;

export default { READ_ASSESSMENTS };
