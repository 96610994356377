import { gql } from '@apollo/client';

export const DIAGNOSTIC_ASSESSMENT = gql`
    query diagnosticAssessment ($assessmentId: ID!) {
        diagnosticAssessment (id: $assessmentId) {
            id
            staffName
            school {
                Name
            }
        }
    }`;

export default DIAGNOSTIC_ASSESSMENT;
