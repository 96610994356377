import React from 'react';
import PropTypes from 'prop-types';

const ContextMenuItem = ({
    className = 'context-menu__item',
    onClick,
    extraClassName,
    text,
    children,
}) => {
    if (extraClassName) {
        className += ' ' + extraClassName;
    }

    return (
        <li className={className} onClick={onClick}>
            { text || children }
        </li>
    );
}

ContextMenuItem.propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
    extraClassName: PropTypes.string,
    text: PropTypes.string
};

export default ContextMenuItem;