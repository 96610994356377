export const actionTypes = {
    ADD_WORD: 'ADD_WORD',
    REMOVE_WORD: 'REMOVE_WORD',
    SET_CURRENT_ASSESSMENT: 'SET_CURRENT_ASSESSMENT',
    SET_CURRENT_SUBMISSION: 'SET_CURRENT_SUBMISSION',
    SET_SAVING: 'SET_SAVING',
    UPDATE_SUBMISSION_METRIC: 'UPDATE_SUBMISSION_METRIC',
    UPDATE_PARAGRAPH_METRIC: 'UPDATE_PARAGRAPH_METRIC',
    UPDATE_SENTENCE_METRIC: 'UPDATE_SENTENCE_METRIC',
    SET_IS_SUBMISSION_UPDATING: 'SET_IS_SUBMISSION_UPDATING',
    SET_CURRENT_MARKING: 'SET_CURRENT_MARKING',
    SET_MARKING_MODE: 'SET_MARKING_MODE',
};

/**
 * Adds a word type to a sentence.
 *
 * @param {string} field        Name of the field to increase.
 * @param {string} list         Name of the list to add word to.
 * @param {string} accumulator  Name of the field on submission to increase.
 * @param {int}    index        The index position of the word in the sentence.
 * @param {string} word         The word to add.
 * @param {string} paragraph    The paragraph the word belongs to.
 * @param {string} sentence     The sentence the word belongs to.
 *
 * @returns {{type: string, field: *, list: *, accumulator: *,
 * index: int, word: *, paragraph: *, sentence: *}}
 */
export function addWord(field, list, accumulator, index, word, paragraph, sentence) {
    return {
        type: actionTypes.ADD_WORD,
        field,
        list,
        accumulator,
        index,
        word,
        paragraph,
        sentence,
    };
}

export function removeWord(field, list, accumulator, index, paragraph, sentence) {
    return {
        type: actionTypes.REMOVE_WORD,
        field,
        list,
        accumulator,
        index,
        paragraph,
        sentence,
    };
}

/**
 * Set the current assessment.
 *
 * @param assessment
 * @returns {{type: string, assessment: *}}
 */
export function setCurrentAssessment(assessment) {
    return {
        type: actionTypes.SET_CURRENT_ASSESSMENT,
        assessment,
    };
}

/**
 * Set the current submission for the marker.
 *
 * @param submission
 * @returns {{type: string, submission: *}}
 */
export function setCurrentSubmission(submission) {
    return {
        type: actionTypes.SET_CURRENT_SUBMISSION,
        submission,
    };
}

/**
 * Set state to saving.
 *
 * @param saving
 * @returns {{type: string, saving: *}}
 */
export function setSaving(saving) {
    return {
        type: actionTypes.SET_SAVING,
        saving,
    };
}

/**
 * Update a metric on the sentence.
 *
 * @param {string} metric
 * @param {Map}    paragraph
 * @param {Map}    sentence
 * @param {*}      value
 * @param {string|null} accumulator Optional accumulator on the submission
 *
 * @returns {{type: string, metric: *, paragraph: *, sentence: *, value: *}}
 */
export function updateSentenceMetric(metric, paragraph, sentence, value, accumulator = null) {
    return {
        type: actionTypes.UPDATE_SENTENCE_METRIC,
        metric,
        paragraph,
        sentence,
        value,
        accumulator,
    };
}

/**
 * Update a metric on the sentence.
 *
 * @param {string} metric
 * @param {Map}    paragraph
 * @param {*}      value
 *
 * @returns {{type: string, metric: *, paragraph: *, value: *}}
 */
export function updateParagraphMetric(metric, paragraph, value) {
    return {
        type: actionTypes.UPDATE_PARAGRAPH_METRIC,
        metric,
        paragraph,
        value,
    };
}

/**
 * Update an individual metric on the submission.
 *
 * @param metric
 * @param value
 * @returns {{type: string, metric: *, value: *}}
 */
export function updateSubmissionMetric(metric, value) {
    return {
        type: actionTypes.UPDATE_SUBMISSION_METRIC,
        metric,
        value,
    };
}

export function setIsSubmissionUpdating(isSubmissionUpdating) {
    return {
        type: actionTypes.SET_IS_SUBMISSION_UPDATING,
        isSubmissionUpdating,
    };
}

export function setCurrentMarking(marking) {
    return {
        type: actionTypes.SET_CURRENT_MARKING,
        marking,
    };
}

export function setMarkingMode(markingMode) {
    return {
        type: actionTypes.SET_MARKING_MODE,
        markingMode,
    }
}
