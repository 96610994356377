import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { setNotification } from '../actions/global';

const Notification = ({ autoHideTimer = 5000 }) => {
    const { message, messageType } = useSelector(state => state.global);
    const dispatch = useDispatch();
    let messageTimeout = null;

    /**
     * Handle page loading with an error message, state might be equal so timeout might not trigger.
     */
    useEffect(
        () => {
            if (message && !messageTimeout) {
                messageTimeout = setTimeout(() => {
                    messageTimeout = null;
                    dispatch(setNotification('', ''));
                }, autoHideTimer);
            }
        },
        [message, messageTimeout, autoHideTimer]
    )

    const classNames = "alert alert-" + messageType + " alert--no-margin";

    return !message ? '' : (
        <div className="message--global">
            <p className={classNames}>{message}</p>
        </div>
    );
}

Notification.propTypes = {
    autoHideTimer: PropTypes.number,
    message: PropTypes.string,
    messageType: PropTypes.string,
};

export default Notification;