import React from 'react';
import PropTypes from 'prop-types';

const  Dialog = ({ visible, modal, width = "small", onContainerClick, children }) => {
    const onClickContainer = (e) => {
        e.stopPropagation();

        // don't execute unless container clicked directly
        if (onContainerClick && e.target === e.currentTarget) {
            onContainerClick();
        }
    }

    const classNames = [
        'dialog',
        'dialog--paper',
        'dialog--' + width,
        'dialog--centered'
    ];

    if (modal) {
        return (
            visible ? <div className="dialog-container" onClick={onClickContainer}>
                <div className={classNames.join(' ')}>
                    { children }
                </div>
            </div> : null
        );
    }

    return visible ? <div className={classNames.join(' ')}>
        { children }
    </div> : null;
}

Dialog.propTypes = {
    visible: PropTypes.bool,
    columns: PropTypes.number,
    modal: PropTypes.bool,
    onContainerClick: PropTypes.func
};

export default Dialog;