import React, { useEffect, useRef } from "react";
import {useDispatch, useSelector} from "react-redux";
import { loginUser } from "../actions/auth";
import { updateField } from "../actions/form";
import Button from "./Button";

const FORM_NAME = 'loginForm';

const LoggedInUser = ({ scopes, title, children }) => {
    const { error, errorMessage, loggedIn } = useSelector(state => state.auth);
    const { username, password } = useSelector(state => state.form)[FORM_NAME] || {};
    const userNameField = useRef(null);
    const passwordField = useRef(null);
    const dispatch = useDispatch();

    /**
     * Focus on username field when component loads
     */
    useEffect(() => {
        if (userNameField.current) {
            userNameField.current.focus();
        };
    }, []);

    const onFieldChange = (field, value) => dispatch(updateField(FORM_NAME, field, value));

    const onSubmit = () => {
        dispatch(loginUser(username, password, scopes)).then(() => {
            // clear fields
            if (loggedIn) {
                onFieldChange('username', null);
                onFieldChange('password', null);
            }
        });
    }

    const checkEnter = (e) => e.keyCode === 13 && onSubmit();

    return loggedIn ? (
        <div>{children}</div>
    ) : (
        <div className="md-grid">
            <div className="md-cell md-cell--6 login-form centered">
                {title ? <h2>{title}</h2> : null}

                {error ? <div className="form__error"><p>{errorMessage}</p></div> : null}


                <div className="form-input">
                    <input
                        ref={userNameField}
                        id="userName"
                        type="text"
                        className="text-field"
                        placeholder="Email"
                        value={username || ""}
                        onChange={(e) => onFieldChange('username', userNameField.current.value)}
                        onKeyDown={(e) => checkEnter(e)}
                    />
                </div>

                <div className="form-input">
                    <input
                        ref={passwordField}
                        id="password"
                        type="password"
                        className="text-field"
                        placeholder="Password"
                        value={password || ""}
                        onChange={(e) => onFieldChange('password', passwordField.current.value)}
                        onKeyDown={(e) => checkEnter(e)}
                    />
                </div>

                <div className="form-actions form-actions--align-right">
                    <Button onClick={onSubmit} color="primary">Login</Button>
                </div>
            </div>
        </div>
    );
}

export default LoggedInUser;