import { actionTypes } from '../actions/submissionMarker';

const defaultState = {
    sentenceToDelete: null,
    mergeData: {
        Sentence: null,
        MergeDirection: null,
    },
    isLoading: false,
    sentenceTypeCheckFail: false,
    timeMarking: 0,
};

export default function submissionMarker(state = defaultState, action) {
    switch (action.type) {
    case actionTypes.SET_SENTENCE_TO_DELETE:
        return {
            ...state,
            sentenceToDelete: action.sentenceToDelete,
        };

    case actionTypes.SET_SENTENCE_TO_MERGE:
        return {
            ...state,
            mergeData: {
                Sentence: action.sentenceToMerge,
                MergeDirection: action.mergeDirection,
            },
        };

    case actionTypes.SET_LOADING:
        return {
            ...state,
            isLoading: action.isLoading,
        };

    case actionTypes.SET_SENTENCE_TYPE_CHECK_FAIL:
        return {
            ...state,
            sentenceTypeCheckFail: action.sentenceTypeCheckFail,
        };

    case actionTypes.UPDATE_TIMER:
        return {
            ...state,
            timeMarking: action.time,
        };

    default: return state;
    }
}
