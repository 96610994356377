import { gql } from '@apollo/client';

const FullSubmissionFragment = gql`
    fragment FullSubmissionFragment on analysedPiece {
        id
        Text
        LastEdited
        AvgPS
        AvgPW
        AvgS
        Coherence
        Development
        Repetition
        Dens
        IncSS
        LongS
        NoP
        TotalProper
        TotalPunc
        NoS
        TotalCap
        TotalPNE
        TotalSlang
        TotalSpell
        TotalTxt
        ShortS
        TotalSpec
        SSAdv
        Text
        TopSS
        TotalW
        LengthOfMarkingTime
        AppropriateTextType
        AppropriateOpening
        AppropriateClosing
        ParagraphRepetition
        OriginalCoherence
        OriginalRepetition
        MarkedAt
        ReviewedAt
    }`;

export default FullSubmissionFragment;
