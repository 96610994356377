import { combineReducers } from 'redux';
import { auth } from '../../reducers/auth';
import form from '../../reducers/form';
import formFields from '../../components/Form/reducers';
import global from '../../reducers/global';
import mark from './mark';
import review from './review';
import submissionMarker from './submissionMarker';

export const rootReducer = combineReducers({
    auth,
    form,
    formFields,
    global,
    mark,
    review,
    submissionMarker,
});

export default rootReducer;
