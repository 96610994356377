export const SCOPE_MARKER = 'marker';

const defaultConfig = {
    // default, env agnostic config variables

    // Sentence metrics/lists used for marking, if no list variable, then not included
    // in lists but still included in metrics below sentence.
    // If no label, not included below sentence or in menu.
    sentenceMetrics: [
        {
            accumulator: 'TotalW',
            field: 'SAW',
            label: 'Number of words',
            multiWord: false,
        },
        {
            accumulator: 'TotalCap',
            field: 'SACap',
            label: 'Capitalised',
            multiWord: false,
        },
        {
            accumulator: 'TotalPunc',
            field: 'SAPunc',
            key: 'punctuation-error',
            label: 'Punctuation Error',
            list: 'PunctuationErrors',
            multiWord: false,
        },
        {
            accumulator: 'TotalSpell', // variable on Submission to sum values
            field: 'SASpell', // field on sentence to store count
            key: 'spelling-error', // key for this metric
            label: 'Spelling Error', // label for metric, used in menu and titles
            list: 'SpellingErrors', // name of list to store array of selected values
            multiWord: false, // can this metric contain multiple words
        },
        {
            accumulator: 'TotalPNE',
            field: 'SAPNE',
            key: 'proper-noun-error',
            label: 'Proper Noun Cap Error',
            list: 'ProperNounErrors',
            multiWord: false,
        },
        {
            accumulator: 'TotalSlang',
            field: 'SASlang',
            key: 'slang',
            label: 'Slang',
            list: 'Slang',
            multiWord: false,
        },
        {
            accumulator: 'TotalTxt',
            field: 'SATxt',
            key: 'txt-speak',
            label: 'Txt Speak',
            list: 'TxtSpeak',
            multiWord: false,
        },
        {
            accumulator: 'TotalProper',
            field: 'SAProper',
            key: 'proper-noun',
            label: 'Proper Noun',
            list: 'ProperNouns',
            multiWord: true,
        },
        {
            accumulator: 'TotalSpec',
            field: 'SASpec',
            key: 'specific',
            label: 'Specific',
            list: 'Specifics',
            multiWord: false,
        },
        {
            accumulator: 'TotalPunc',
            field: 'SAPunc',
            key: 'punctuation-space-error',
            list: 'PunctuationSpaceErrors',
            multiWord: false,
        },
    ],
    sentenceTypes: {
        SSA: 'Adverb',
        SSCast: 'Cast and flick',
        SSColon: 'Colon: and flow',
        SSCon: 'Conjunction Start',
        SSed: 'Ed start',
        SSEm: 'Em Dash',
        SSExp: 'Explore subject',
        SSIng: 'Ing start',
        SSO: 'Other undefined',
        SSPow: 'Power',
        SSP: 'Preposition',
        SSQ: 'Question',
        SSRWB: 'Red, White, Blue',
        SSRK: 'Royal knockout',
        SSSC: 'Semi Colon',
        SSS: 'Simple',
        SSComp: 'Simple Compound',
        SSV: 'Very short',
        SSW: 'W Start',
        SSIR: 'Run On',
        SSI: 'Incomplete',
    },
    paragraphSelectOptions: [
        {
            value: '0',
            label: '0',
        },
        {
            value: '0.25',
            label: '0.25',
        },
        {
            value: '0.5',
            label: '0.5',
        },
        {
            value: '0.75',
            label: '0.75',
        },
        {
            value: '1',
            label: '1',
        },
    ],
};

export default defaultConfig;
