import { actionTypes } from '../actions/form';

const form = (state = {}, action) => {
    switch (action.type) {
    case actionTypes.UPDATE_FIELD: {
        const formState = {...state[action.formName]} || {};

        return {
            ...state,
            [action.formName]: {
                ...formState,
                [action.fieldName]: action.value,
            },
        };
    }

    default:
        return state;
    }
};

export default form;
