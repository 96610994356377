import React from 'react';
import PropTypes from 'prop-types';

const TableHeader = ({
    cellClassName = '',
    cellClick = () => {},
    containerClassName = '',
    fields = {},
    onClearSearch = () => {},
    onOpenSearch = () => {},
    rowClassName = '',
    searchable = false,
    searchedFields = {},
    sortDirection = '',
    sortField = '',
}) => {

    /**
     * Render icons for sorting the column.
     *
     * @param {String} fieldName
     * @returns {*}
     */
    const renderSort = (fieldName) => {
        if (fieldName === sortField) {
            return <div className="md-icon-separator">
                <i className="md-icon material-icons md-collapser md-collapser--flipped icon--pointer">{sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward'}</i>
                <span className="md-icon-text">{fields[fieldName].label}</span>
            </div>;
        } else {
            return <div className="md-icon-separator">
                <i className="md-icon material-icons md-collapser md-collapser--flipped icon--pointer">sort</i>
                <span className="md-icon-text">{fields[fieldName].label}</span>
            </div>;
        }
    }

    return (
        <thead className={containerClassName}>
            <tr className={rowClassName}>
                {Object.keys(fields).map((fieldName) => <th
                    className={cellClassName + (fields[fieldName].className ? ' ' + fields[fieldName].className : '')}
                    scope="col"
                    key={fieldName}
                    onClick={() => cellClick(fieldName)}
                >
                    {fieldName === 'actions' && searchable ? (
                        <>
                            {Object.keys(searchedFields).length > 0 && (
                                <i
                                    className="material-icons icon icon--large icon--rounded icon--primary icon--pointer"
                                    title="Search Submissions"
                                    style={{ float: 'right' }}
                                    onClick={onClearSearch}
                                >
                                    close
                                </i>
                            )}
                            <i
                                className="material-icons icon icon--large icon--rounded icon--primary icon--pointer"
                                title="Search Submissions"
                                style={{ float: 'right' }}
                                onClick={onOpenSearch}
                            >
                                search
                            </i>
                        </>
                    ) : (
                        fields[fieldName].sort ? renderSort(fieldName) : fields[fieldName].label
                    )}
                </th>)}
            </tr>
        </thead>
    );
}

TableHeader.propTypes = {
    cellClassName: PropTypes.string,
    cellClick: PropTypes.func,
    containerClassName: PropTypes.string,
    fields: PropTypes.object,
    onClearSearch: PropTypes.func,
    onOpenSearch: PropTypes.func,
    rowClassName: PropTypes.string,
    searchable: PropTypes.bool,
    searchedFields: PropTypes.object,
    sortDirection: PropTypes.string,
    sortField: PropTypes.string,
};

export default TableHeader;