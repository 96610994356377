import { gql } from "@apollo/client";
import { MarkingQueryFragment } from "./MarkingQueryFragment";

export const UPDATE_MARKING = gql`
    mutation updateDiagnosticMarking($markingId: ID!, $input: MarkingInput) {
        updateDiagnosticMarking(markingId: $markingId, input: $input) {
            ...MarkingQueryFragment
        }
    }
    ${MarkingQueryFragment}
`;

export default { UPDATE_MARKING };
