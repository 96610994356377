import moment from 'moment';
import jwt from 'jsonwebtoken';
import { actionTypes } from '../actions/auth';

const defaultState = {
    error: false,
    errorMessage: null,
    expiresIn: null,
    loading: false,
    loggedIn: false,
    loggedInAt: null,
    refreshToken: null,
    scopes: [],
    token: null,
    username: null,
};

export const auth = (state = defaultState, action) => {
    switch (action.type) {
    case actionTypes.LOGIN_REQUEST:
        return Object.assign({}, state, {
            loading: true,
            error: false,
        });

    case actionTypes.LOGIN_SUCCESS:
        return Object.assign({}, state, {
            loggedIn: true,
            error: false,
            // expiresIn: action.data.expires_in,
            loading: false,
            token: action.data.token,
            loggedInAt: moment().format(),
        });

    case actionTypes.LOGIN_USER_SUCCESS: {
        // decode token and extract scopes for easy access
        const token = jwt.decode(action.data.token);

        return Object.assign({}, state, {
            loggedIn: true,
            error: false,
            loading: false,
            token: action.data.token,
            scopes: token.scopes,
            // expiresIn: action.data.expires_in,
            loggedInAt: moment().format(),
            username: token.fn ? `${token.fn} ${token.ln}` : '',
        });
    }

    case actionTypes.LOGIN_ERROR:
        return Object.assign({}, state, {
            loggedIn: false,
            error: true,
            errorMessage: action.message,
            loading: false,
            token: null,
        });

    case actionTypes.LOGOUT:
        return Object.assign({}, defaultState);

    default:
        return Object.assign({}, state);
    }
};

export default auth;
