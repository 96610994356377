import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ children, color, type, btnRef, ...otherProps }) => {
    const classNames = [
        'btn'
    ];

    if (color) {
        classNames.push('btn--' + color);
    }

    return (
        <button type={type || 'button'} ref={btnRef} className={classNames.join(' ')} {...otherProps}>
            {children}
        </button>
    );
}

Button.propTypes = {
    color: PropTypes.string,
    type: PropTypes.string
};

export default Button;