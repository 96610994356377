import React from 'react';
import PropTypes from 'prop-types';
import { defaultProps, getContainerClass, getFieldClass, getLabel } from '../config';

const SelectField = ({
    containerClass,
    emptyString,
    fieldClass,
    listItems,
    labelClass,
    label,
    name,
    onChange,
    optionKey,
    optionValue,
    fieldRef,
    value,
    modifiers,
    additionalModifiers,
    ...otherProps
}) => (
    <div className={getContainerClass(containerClass, label)}>
        {getLabel(label, name, containerClass, fieldClass, labelClass)}
        <select
            onChange={onChange}
            ref={fieldRef}
            value={value ? value : ''}
            className={getFieldClass(modifiers, fieldClass, label, additionalModifiers)}
            {...otherProps}
        >
            {emptyString && (
                <option key={0} value={0}>{emptyString}</option>
            )}
            {listItems.map((item, index) => (
                <option key={index} value={item[optionKey]}>
                    {item[optionValue]}
                </option>
            ))}
        </select>
    </div>
);

SelectField.defaultProps = {
    ...defaultProps,
    fieldClass: 'select-field',
    optionKey: 'value',
    optionValue: 'label'
};

SelectField.propTypes = {
    emptyString: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    label: PropTypes.string,
    listItems: PropTypes.array,
    optionKey: PropTypes.string,
    optionValue: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SelectField;