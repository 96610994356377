import React from 'react';
import PropTypes from 'prop-types';

const ContextMenu = ({
    className = 'context-menu',
    containerClassName = 'context-menu__container',
    extraClassName,
    listClassName = 'context-menu__list',
    onClick,
    children,
}) => {
    return (
        <div className={containerClassName}>
            <nav className={className + (extraClassName ? ' ' + extraClassName : '')} onClick={onClick}>
                <ul className={listClassName}>
                    { children }
                </ul>
            </nav>
        </div>
    );
}

ContextMenu.propTypes = {
    bgClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    className: PropTypes.string,
    extraClassName: PropTypes.string,
    listClassName: PropTypes.string,
    onClick: PropTypes.func,
    onDisable: PropTypes.func,
};

export default ContextMenu;
