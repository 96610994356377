export const actionTypes = {
    FORM_CLEAR: 'FORM_CLEAR',
    FORM_UPDATE_FIELD: 'FORM_UPDATE_FIELD',
};

export function clearForm(formName) {
    return {
        type: actionTypes.FORM_CLEAR,
        formName,
    };
}

export function updateField(formName, fieldName, fieldValue) {
    return {
        type: actionTypes.FORM_UPDATE_FIELD,
        formName,
        fieldName,
        fieldValue,
    };
}
