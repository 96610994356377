import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

const SubmissionMarkerSpace = ({
    className = 'marker__sentence-space',
    isActive,
    offset,
    parentKey,
    type,
}) => {
    /**
     * Get the css class name if we have a current type.
     *
     * @param className
     * @param types
     * @param isActive
     * @returns {*}
     */
    const getClassName = (className, types, isActive) => {
        const original = className;

        if (types.length > 0) {
            types.map(type => {
                className += ' ' + original + '--' + type;
            })

            className += ' ' + original + '--selected';
        }

        if (types.length > 2) {
            className += ' ' + original + '--multi-error';
        }

        // the word is "active" - menu selection is active
        if (isActive) {
            className += ' ' + original + '--active';
        }

        return className;
    }

    const selected = useMemo(
        () => {
            return type.words && Object.keys(type.words).includes(offset.toString()) &&
                type.words[offset.toString()] === ' ';
        },
        [offset, type]
    );
    const clickHandler = useCallback(
        (e) => {
            e.stopPropagation();
            if (selected) {
                type.onRemove(offset);
            } else {
                type.onAdd(offset, ' ');
            }
        },
        [selected, type, offset]
    );

    return (
        <span
            key={parentKey + '_space'}
            className={selected ?
                getClassName(className, ['punctuation-error'], false) :
                getClassName(className, [], isActive)
            }
            onDoubleClick={clickHandler}
        >
            &nbsp;
        </span>
    );
}

SubmissionMarkerSpace.propTypes = {
    className: PropTypes.string,
    isActive: PropTypes.bool,
    offset: PropTypes.number,
    parentKey: PropTypes.string.isRequired,
    type: PropTypes.object,
};

export default SubmissionMarkerSpace;

