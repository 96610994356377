import React, { useRef, useState } from 'react';
import { autoUpdate, flip, FloatingPortal, useFloating } from "@floating-ui/react";
import PropTypes from 'prop-types';

import SelectField from '../../../components/Form/Fields/SelectField';
import ContextMenu from "../../../components/ContextMenu/ContextMenu";
import ContextMenuItem from '../../../components/ContextMenu/ContextMenuItem';
import SubmissionWriterWord from './SubmissionMarkerWord';
import SubmissionMarkerMultiWord from './SubmissionMarkerMultiWord';
import SubmissionWriterSpace from './SubmissionMarkerSpace';
import SubmissionMarkerSentenceMetrics from './SubmissionMarkerSentenceMetrics';

const DEFAULT_STATE = {
    currentWords: [],
    currentWordOffsets: [],
    showWordSelection: false,
    wordSelectionX: 0,
    wordSelectionY: 0,
};

const SubmissionMarkerSentence = ({
    onScrollSentence,
    onSentenceMetricChange,
    paragraph,
    sentence,
    sentenceMetrics,
    sentenceTypes,
    wordTypes,
    onSentenceToDelete,
    onSentenceToMerge,
    isFirstInSentence,
    isLastInSentence,
    canDelete,
    isSelected,
}) => {
    // sentence type dropdown
    const sentenceTypeField = useRef(null);
    const sentenceDiv = useRef(null);
    // This is to prevent events from stacking.
    const eventRef = useRef(null);

    const [state, setState] = useState(DEFAULT_STATE);

    /**
     *
     * @param types
     * @param type
     * @returns {*|boolean}
     */
    const checkWordInTypes = (types, type) => {
        const offset = state.currentWordOffsets[0];
        const currentWord = state.currentWords[0];

        const isList = typeof types[type].words[offset.toString()] === 'object';
        const value = isList ? types[type].words[offset.toString()][0] : types[type].words[offset.toString()];

        return Object.keys(types[type].words).includes(offset.toString()) && value === currentWord;
    }

    /**
     * Add word to a list, wrapper to provide ability to do multiple words.
     *
     * @param type
     */
    const toggleWord = (type) => {
        const offset = state.currentWordOffsets[0];
        const currentWord = wordTypes[type].multiWord ? state.currentWords : state.currentWords[0];

        if (checkWordInTypes(wordTypes, type)) {
            wordTypes[type].onRemove(offset, currentWord);
        } else {
            wordTypes[type].onAdd(offset, currentWord);
        }
    }

    /**
     * Remove right most word from selection.
     */
    const selectPreviousWord = () => {
        setState((prevState) => {
            const newState = {...prevState};

            const newWords = [ ...prevState.currentWords ];
            const newOffsets = [ ...prevState.currentWordOffsets ];
            // If no words or only one word, do nothing
            if (prevState.currentWordOffsets.length < 2) {
                return;
            }

            // remove last item
            newWords.pop();
            newOffsets.pop();

            newState.currentWords = newWords;
            newState.currentWordOffsets = newOffsets;

            return newState;
        });
    }

    /**
     * Adds the next word in the sentence (if there is one) to the selected words, allows "multi-word" selection.
     */
    const selectNextWord = () => {
        setState((prevState) => {
            const newState = { ...prevState };

            const newWords = [ ...prevState.currentWords ];
            const newOffsets = [ ...prevState.currentWordOffsets ];
            const tokens = typeof sentence.Tokens === 'string' ? JSON.parse(sentence.Tokens) : sentence.Tokens;
            const rightMostWord = prevState.currentWordOffsets[prevState.currentWordOffsets.length - 1];
            let rightMostWordIndex = null;

            // find current word in tokens
            for (let i = 0; i < tokens.length; i++) {
                // found token of right most word
                if (tokens[i].characterOffsetBegin == rightMostWord) {
                    rightMostWordIndex = i;
                    break;
                }
            }

            // the next word exists, so select it
            if (rightMostWordIndex !== null && typeof tokens[rightMostWordIndex + 1] !== 'undefined') {
                const nextWord = tokens[rightMostWordIndex + 1];
                newWords.push(nextWord.originalText);
                newOffsets.push(nextWord.characterOffsetBegin);

                newState.currentWords = newWords;
                newState.currentWordOffsets = newOffsets;
            }

            return newState;
        });
    };

    /**
     * Listen for arrow keys + shift when menu is open to select multiple words
     *
     * @param {Event} e
     */
    const arrowKeyListener = (e) => {
        // left arrow
        if (e.keyCode === 37 && e.shiftKey) {
            e.preventDefault();
            selectPreviousWord();

            // right arrow
        } else if (e.keyCode === 39 && e.shiftKey) {
            e.preventDefault();
            selectNextWord();
        }
    }

    /**
     * Shows the menu for selecting a word type.
     *
     * @param e
     * @param offset
     * @param word
     */
    const showWordSelection = (e, offset, word) => {
        e.preventDefault();

        setState({
            currentWords: [word],
            currentWordOffsets: [offset],
            showWordSelection: true,
            wordSelectionX: e.clientX,
            wordSelectionY: e.clientY
        });

        eventRef.current = arrowKeyListener;

        // add listener for arrow keys
        document.addEventListener('keydown', eventRef.current);
    }

    /**
     * Hides the menu for selecting a word type.
     *
     * @param e
     */
    const hideWordSelection = (e) => {
        e.preventDefault();

        setState({
            currentWords: [],
            currentWordOffsets: [],
            showWordSelection: false,
            wordSelectionX: 0,
            wordSelectionY: 0
        });

        // add listener for arrow keys
        document.removeEventListener('keydown', eventRef.current);
    }

    /**
     * Find a matching type which is a "multi-word".
     *
     * @param text
     * @param wordTypes
     * @param offset
     *
     * @returns {*}
     */
    const getMultiWordType = (text, wordTypes, offset) => {
        let foundType = null;

        Object.keys(wordTypes).some(type => {
            // check if index is present and the word matches current word (not a space)
            if (
                wordTypes[type].multiWord === true &&
                Object.keys(wordTypes[type].words).includes(offset.toString())
            ) {
                foundType = type;
                return true;
            }

            return false;
        });

        return foundType;
    }

    /**
     * Find a matching type which isn't a "multi-word".
     *
     * @param text
     * @param wordTypes
     * @param offset
     * @param startOffset start of the phrase if multi word
     *
     * @returns {*[]}
     */
    const getWordTypes = (text, wordTypes, offset, startOffset = null) => {
        const foundTypes = [];

        Object.keys(wordTypes).map(type => {
            // check if index is present and the word matches current word (not a space)
            if (
                wordTypes[type].words &&
                (
                    Object.keys(wordTypes[type].words).includes(offset.toString()) &&
                    wordTypes[type].words[offset.toString()] === text
                ) || (
                    wordTypes[type].multiWord === true && startOffset &&
                    Object.keys(wordTypes[type].words).includes(startOffset.toString())
                )
            ) {
                foundTypes.push(type);
            }
        });

        return foundTypes;
    }

    /**
     * Render the individual word given.
     *
     * @param renderedWords
     * @param sentence
     * @param hasSpace
     * @param index
     * @param offset
     * @param wordTypes
     * @param text
     * @param isMultiWord
     * @param startOffset only use this if isMultiWord is set to true.
     */
    const renderWord = (
        renderedWords,
        sentence,
        hasSpace,
        index,
        offset,
        wordTypes,
        text,
        isMultiWord = false,
        startOffset
    ) => {
        const types = getWordTypes(text, wordTypes, offset, startOffset);

        // check for a space
        if (hasSpace) {
            renderedWords.push(
                <SubmissionWriterSpace
                    key={'SubmissionWriterSpace_' + sentence.id + '_' + index}
                    parentKey={'SubmissionWriterSpace_' + sentence.id + '_' + index}
                    // for spaces we just add the index of the space
                    offset={offset}
                    type={wordTypes['punctuation-space-error']}

                />
            );
        }

        // ui handlers for adding/removing word
        const contextMenu = (e) => {
            refs.setPositionReference(e.currentTarget);
            showWordSelection(e, offset, text);
        };
        const onRemove = types.length > 0 ? (e) => {
            e.stopPropagation();
            wordTypes[types[0]].onRemove(isMultiWord ? startOffset : offset);
        } : null;
        let onDoubleClick = onRemove ? onRemove : contextMenu;

        // if it is part of a multi-word, don't allow double click if it doesn't have a type, if it has a type, it
        // can be double clicked to remove the type
        if (isMultiWord && !onRemove) {
            onDoubleClick = () => true;
        }

        renderedWords.push(
            <SubmissionWriterWord
                key={'SubmissionWriterWord_' + sentence.id + '_' + index}
                parentKey={'SubmissionWriterWord_' + sentence.id + '_' + index}
                isActive={state.currentWordOffsets.includes(offset) && state.currentWords.includes(text)}
                onContextMenu={contextMenu}
                // if multi-word can't double click select
                onDoubleClick={onDoubleClick}
                offset={offset}
                types={types}
                text={text}
            />
        );
    }

    /**
     * Render all the words of a sentence.
     *
     * @param sentence
     * @param wordTypes
     *
     * @returns {*[]}
     */
    const renderWords = (sentence, wordTypes) => {
        let previousWord = null;
        const renderedWords = [];

        // treat them as a stack
        const tokens = typeof sentence.Tokens === 'string' ? JSON.parse(sentence.Tokens) : sentence.Tokens;
        const noTokens = tokens.length;
        let word, index, offset, text, hasSpace;

        for (let i = 0; i < noTokens; i++) {
            word = {...tokens[i]};
            offset = word['characterOffsetBegin'];
            text = word['originalText'];
            const multiWordType = getMultiWordType(text, wordTypes, offset);

            // if multiword type, we render the multiword, and the words as children.
            if (multiWordType) {
                const multiWord = wordTypes[multiWordType];
                const wordList = multiWord.words;
                // check for before multi-words were supported
                const words = Array.isArray(wordList[offset]) ? wordList[offset] : [wordList[offset]];
                const noWords = words.length;
                const startOffset = offset;

                // store child words to pass through to "multi-word"
                const childWords = [];

                // loop over each word
                for (let j = 0; j < noWords; j++) {
                    index = i + j;
                    word = {...tokens[index]};
                    offset = word['characterOffsetBegin'];
                    text = word['originalText'];
                    hasSpace = previousWord && word['characterOffsetBegin'] - previousWord['characterOffsetEnd'] >= 1;

                    renderWord(childWords, sentence, hasSpace, index, offset, wordTypes, text, true, startOffset);
                    // update previous word
                    previousWord = word;
                }

                renderedWords.push(
                    <SubmissionMarkerMultiWord
                        key={'SubmissionWriterMultiWord_' + sentence.id + '_' + index}
                        parentKey={'SubmissionWriterMultiWord_' + sentence.id + '_' + index}
                        offset={offset}
                        onDoubleClick={() => multiWord.onRemove(startOffset)}
                        type={multiWordType}
                    >
                        {childWords}
                    </SubmissionMarkerMultiWord>
                );


                // update index to skip the words we've just added
                i += noWords - 1;
            } else {
                index = i;
                hasSpace = previousWord && word['characterOffsetBegin'] - previousWord['characterOffsetEnd'] >= 1;

                renderWord(renderedWords, sentence, hasSpace, index, offset, wordTypes, text);

                // update previous word
                previousWord = word;
            }
        }

        return renderedWords;
    }

    const { refs, floatingStyles } = useFloating({
        open: state.showWordSelection,
        middleware: [flip()],
        placement: "bottom-start",
        whileElementsMounted: autoUpdate,
    });

    return (
        <div className={'marker__sentence' + (isSelected ? ' selected' : '')} key={'sentence_' + sentence.id}>
            {state.showWordSelection && (
                <FloatingPortal>
                    <div className={"modal-overlay"} onClick={hideWordSelection} />
                    <div
                        ref={refs.setFloating}
                        style={floatingStyles}
                    >
                        <ContextMenu onClick={hideWordSelection}>
                            {Object.keys(wordTypes).map(type => (
                                wordTypes[type].label ? (
                                    <ContextMenuItem
                                        key={'ContextMenu_' + sentence.id + '_' + type}
                                        onClick={() => toggleWord(type)}
                                        extraClassName="context-menu__item--with-padding"
                                    >
                                        {checkWordInTypes(wordTypes, type) && (
                                            <i className="material-icons">done</i>
                                        )}
                                        {wordTypes[type].label}
                                    </ContextMenuItem>
                                ) : null
                            ))}
                        </ContextMenu>
                    </div>
                </FloatingPortal>
            )}
            <div className="md-grid" ref={sentenceDiv}>
                <div className="md-cell md-cell--8">
                    <p className="marker--text">
                        {renderWords(sentence, wordTypes)}
                    </p>
                    <SubmissionMarkerSentenceMetrics
                        onSentenceMetricChange={onSentenceMetricChange}
                        paragraph={paragraph}
                        sentence={sentence}
                        sentenceMetrics={sentenceMetrics}
                    />
                </div>
                <div
                    className="md-cell md-cell-4"
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'end',
                    }}
                >
                    <SelectField
                        onChange={() => onSentenceMetricChange('SASS', paragraph, sentence, sentenceTypeField?.current?.value)}
                        fieldRef={sentenceTypeField}
                        value={sentence.SASS}
                        emptyString="Sentence Type"
                        listItems={Object.keys(sentenceTypes).map(type => ({
                            value: type,
                            label: sentenceTypes[type]
                        }))}
                        onFocus={() => onScrollSentence(sentenceDiv?.current)}
                    />
                    <div className="marker__sentence__actions">
                        {!isFirstInSentence && (
                            <i
                                className="material-icons icon--rounded icon--medium icon--spacing-right icon--logged-in icon--pointer"
                                onClick={() => onSentenceToMerge(sentence, "up")}
                            >
                                vertical_align_top
                            </i>
                        )}
                        {canDelete && !isLastInSentence && (
                            <i
                                className="material-icons icon--rounded icon--medium icon--spacing-right icon--logged-in icon--pointer"
                                onClick={() => onSentenceToMerge(sentence, "down")}
                            >
                                vertical_align_bottom
                            </i>
                        )}
                        {canDelete && (
                            <i
                                className="material-icons icon--rounded icon--medium icon--error icon--pointer"
                                onClick={() => onSentenceToDelete(sentence)}
                            >
                                delete
                            </i>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

SubmissionMarkerSentence.propTypes = {
    onScrollSentence: PropTypes.func,
    onSentenceMetricChange: PropTypes.func,
    paragraph: PropTypes.object,
    sentence: PropTypes.object,
    sentenceMetrics: PropTypes.array,
    sentenceTypes: PropTypes.object,
    wordList: PropTypes.object,
};

export default SubmissionMarkerSentence;
