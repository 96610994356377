import { gql } from '@apollo/client';

const DIAGNOSTIC_MARKERS = gql`
    query diagnosticMarkers {
        diagnosticMarkers {
            id
            firstName
            surname
            averageMarkingTime
            markedAllTime
            hoursSpentMarking
            markerAverageWords
            correctionRate
        }
    }
`;

export default DIAGNOSTIC_MARKERS;
