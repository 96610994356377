export const MODE_MARKING = 'marking';

export const MODE_REVIEWING = 'reviewing';

export const MODE_MARK_ONE = 'single-marking';

export const MODE_REVIEW_ONE = 'single-reviewing';

export const ACTION_MARK = 'mark';

export const ACTION_REVIEW = 'review';

export const MODE_ACTION_MAP = {
    [MODE_MARKING]: ACTION_MARK,
    [MODE_MARK_ONE]: ACTION_MARK,
    [MODE_REVIEWING]: ACTION_REVIEW,
    [MODE_REVIEW_ONE]: ACTION_REVIEW,
};