import { gql } from '@apollo/client';

const FullParagraphFragment = gql`
    fragment FullParagraphFragment on analysedParagraph {
        id
        PAOrder
        Text
        PAW
        NoS
        PAR
        PAD
        PAC
        Coherence
        Development
        Repetition
        OriginalCoherence
        OriginalDevelopment
        OriginalRepetition
}`;

export default FullParagraphFragment;
