import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import User from '../../includes/User';
import { useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { uniq } from "lodash";

import { DIAGNOSTIC_MARKINGS, GET_NEXT_MARKING } from "../queries/diagnosticMarkingsQuery";
import { DIAGNOSTIC_ASSESSMENT } from "../queries/diagnosticAssessmentQuery";
import { setCurrentAssessment, setCurrentMarking, setMarkingMode } from '../actions/mark';
import {
    updateSortDirection,
    updateSortField,
    updateSearchedFields,
    updateSelectedMarker,
} from '../actions/review';
import { logout } from '../../actions/auth';
import Button from '../../components/Button';
import Loading from "../../components/Loading";
import SubmissionList from '../components/SubmissionList';
import { setNotification } from '../../actions/global';
import { UPDATE_MARKING } from "../queries/updateDiagnosticMarkingMutation";
import {
    ACTION_MARK, ACTION_REVIEW,
    MODE_MARK_ONE,
    MODE_MARKING,
    MODE_REVIEW_ONE,
    MODE_REVIEWING
} from "../includes/marking-flow/constants";
import { getMarkerFormattedName } from "../../includes/utils";

const Submissions = ({ user }) => {
    const dispatch = useDispatch();
    const { assessmentId } = useParams();
    const navigate = useNavigate();

    const currentAssessment = useSelector(state => state.mark.currentAssessment);
    const { sortField, sortDirection, searchedFields, selectedMarker } = useSelector(state => state.review);

    // Lazy query so that we can make a dispatch to the store.
    const [ diagnosticAssessment ] = useLazyQuery(DIAGNOSTIC_ASSESSMENT);

    const { data, loading } = useQuery(
        DIAGNOSTIC_MARKINGS,
        {
            variables: { assessmentId },
            fetchPolicy: 'network-only',
            pollInterval: 5_000,
        }
    );

    const submissions = data?.diagnosticMarkings;

    const markers = useMemo(() => {
        const _markers = submissions?.filter(sub => !!sub.marker).map(sub => sub.marker) || [];

        return uniq(_markers);
    }, [submissions]);

    const [updateMarking] = useMutation(UPDATE_MARKING);
    const [getNextMarking] = useLazyQuery(GET_NEXT_MARKING);

    useEffect(() => {
        dispatch(setCurrentMarking(null));
        dispatch(updateSelectedMarker(null));
    }, []);

    useEffect(
        () => {
            if (!currentAssessment || currentAssessment.id !== assessmentId) {
                diagnosticAssessment({
                    variables: { assessmentId },
                    fetchPolicy: 'network-only'
                }).then(({ data }) => {
                    dispatch(setCurrentAssessment(data.diagnosticAssessment))
                }).catch(error => {
                    if (error?.graphQLErrors) {
                        dispatch(setNotification(error.graphQLErrors[0].message));
                    }
                });
            }
        },
        [currentAssessment, assessmentId]
    );

    /**
     * Handle selecting a marker to review their markings, displays a simple confirm dialogue before navigating away
     * into reviewing mode.
     *
     * @param {number} markerId
     */
    const onMarkerSelect = (markerId) => {
        const selected = markers.find(marker => parseInt(marker.id) === markerId);

        if (!selected) {
            dispatch(updateSelectedMarker(null));
            return;
        }

        const newSelected = { ...selected };

        const message = 'Do you want to review all markings by ' +
            getMarkerFormattedName(newSelected) + '?';

        if (window.confirm(message)) {
            dispatch(updateSelectedMarker(newSelected));

            onStartAll({ markingMode: MODE_REVIEWING, marker: newSelected })
        }
    }

    /**
     * Begin marking/reviewing all relevant submissions from this test.
     *
     * @param {string} markingMode
     * @param {object|null} marker
     */
    const onStartAll = ({ markingMode, marker = null }) => {
        dispatch(setMarkingMode(markingMode));

        getNextMarking({
            variables: {
                assessmentId,
                markingMode,
                ...marker && { markerId: marker.id }
            },
            fetchPolicy: 'network-only',
        }).then(({ data: { getNextMarking } }) => {
            if (getNextMarking === null) {
                const message = `The assessment doesn't have anything to ${markingMode === MODE_MARKING ? ACTION_MARK :
                    ACTION_REVIEW}`;
                const trailMessage = marker ? ` for ${getMarkerFormattedName(marker)}` : '';

                dispatch(updateSelectedMarker(null));
                dispatch(setNotification(message + trailMessage));
            } else {
                dispatch(setCurrentMarking({ ...getNextMarking }));

                navigate('/review/' + assessmentId);
            }
        }).catch(error => {
            if (error.graphQLErrors) {
                dispatch(setNotification(error.graphQLErrors[0].message));
            }
        });
    }

    /**
     * Handles marking/reviewing of a single submission.
     *
     * @param e
     * @param {object} marking
     * @param {string} mode
     */
    const onStart = (e, { marking, mode }) => {
        e.stopPropagation();

        dispatch(setMarkingMode(mode));

        updateMarking({
            variables: {
                markingId: marking.id,
                input: {
                    ...mode === MODE_MARK_ONE && { isMarking: true },
                    ...mode === MODE_REVIEW_ONE && { isReviewing: true },
                },
            }
        }).then(({ data: { updateDiagnosticMarking } }) => {
            dispatch(setCurrentMarking({ ...updateDiagnosticMarking }));

            navigate('/review/' + currentAssessment.id);
        }).catch(error => {
            if (error.graphQLErrors) {
                dispatch(setNotification(error.graphQLErrors[0].message));
            }
        });
    }

    /**
     * Saves sorting options to the store.
     *
     * @param field
     * @param direction
     */
    const onSort = (field, direction) => {
        dispatch(updateSortField(field));
        dispatch(updateSortDirection(direction));
    }

    const onSearch = (searchedFields) => dispatch(updateSearchedFields(searchedFields));
    const goBack = () => navigate('/');
    const doLogout = () => dispatch(logout());

    const pageTitle = currentAssessment && currentAssessment.school
        ? currentAssessment.school.Name + " - " + currentAssessment.staffName
        : "";

    return (
        <div className="marker__submissions">
            <div className="md-grid marker__actions">
                <div className="md-cell md-cell--6">
                    <p>Welcome {user.getUsername()}</p>
                </div>
                <div className="md-cell md-cell--6 marker__actions-right">
                    <Button color="green" onClick={goBack}><i
                        className="material-icons icon--reverse">forward</i> Back</Button>
                    <Button color="green" onClick={doLogout}>Logout <i className="material-icons">exit_to_app</i></Button>
                </div>
            </div>
            <div className="marker__submission-actions">
                <div className="marker__submission-actions-assessment">
                    {pageTitle}
                </div>
                <div style={{
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                    gap: "5px",
                }}>
                    <button onClick={() => onStartAll({ markingMode: MODE_MARKING })}>
                        Mark All
                    </button>
                    <button onClick={() => onStartAll({ markingMode: MODE_REVIEWING })}>
                        Review All
                    </button>
                    <div style={{
                        position: "absolute",
                        color: "grey",
                        fontSize: "10px",
                        top: "100%",
                        width: "100%",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                    }}>
                        (Order of submissions unaffected by table sorting)
                    </div>
                </div>
                {!loading && (
                    <div style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        gap: "5px",
                    }}>
                        Review all by Marker
                        <select
                            style={{ minWidth: "100px" }}
                            onChange={e => {
                                onMarkerSelect(parseInt(e.target.value));
                            }}
                            value={selectedMarker?.id || 0}
                        >
                            <option value={0}>None</option>
                            {markers.map((marker, i) => (
                                <option
                                    key={i}
                                    value={marker.id}
                                >
                                    {getMarkerFormattedName(marker)}
                                </option>
                            ))}
                        </select>
                    </div>
                )}
            </div>

            {loading ? (
                <Loading/>
            ) : (
                <SubmissionList
                    loading={loading}
                    submissions={submissions}
                    onStart={onStart}
                    onSort={onSort}
                    onSearch={onSearch}
                    defaultSearchedFields={searchedFields}
                    defaultSortField={sortField}
                    defaultSortDirection={sortDirection}
                />
            )}
        </div>
    );
}

Submissions.propTypes = {
    user: PropTypes.instanceOf(User).isRequired,
};

export default Submissions;