import jwtDecode from 'jwt-decode';
import dayjs from 'dayjs';
import { SCOPE_MARKER } from '../config/config';
import LoginError from '../includes/LoginError';
import * as storage from '../includes/localStorage';
import {
    AUTH_BEARER_PREFIX,
    AUTH_HEADER,
    AUTH_REFRESH_LEEWAY,
    AUTH_STORAGE_KEY,
} from '../includes/constants';

export const actionTypes = {
    LOGIN_REQUEST: 'LOGIN_REQUEST',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_USER_SUCCESS: 'LOGIN_USER_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGOUT: 'LOGOUT',
};

/**
 * Clear the token from storage.
 */
export const clearToken = () => storage.removeItem(AUTH_STORAGE_KEY);

/**
 * Get tht token from storage.
 *
 * @returns {null|any|{}}
 */
export const getToken = () => storage.retrieve(AUTH_STORAGE_KEY);

/**
 * Save the token to storage.
 *
 * @param {string} token
 */
export const setToken = (token) => {
    try {
        const decoded = jwtDecode(token);
        const expiresIn = (decoded.exp && decoded.iat) ? decoded.exp - decoded.iat : 0;
        const expiresAt = dayjs().add(expiresIn, 's');

        const tokenData = {
            token,
            expiresAt,
        };

        storage.persist(AUTH_STORAGE_KEY, tokenData);

        return tokenData;
    } catch (e) {
        // Token is null.
        // clearToken();
        //
        // return null;
        return null;
    }
};

/**
 * Has the token has expired.
 *
 * @param tokenData
 * @returns {boolean}
 */
export const hasTokenExpired = (tokenData) => {
    if (!tokenData || !tokenData.expiresAt) return true;

    const expires = dayjs(tokenData.expiresAt);
    const now = dayjs();

    return now.isAfter(expires);
};

/**
 * Should the token be refreshed. Checks if current time is between the after the refresh time.
 * Expiry should also be checked with hasExpired.
 *
 * @param tokenData
 * @returns {boolean|boolean}
 */
export const shouldTokenRefresh = (tokenData) => {
    // can't refresh if we don't have a token
    if (!tokenData || !tokenData.expiresAt) return false;

    const refreshTime = dayjs(tokenData.expiresAt).subtract(AUTH_REFRESH_LEEWAY, 's');
    const now = dayjs();

    return now.isSame(refreshTime) || now.isAfter(refreshTime);
};

/**
 * Add the bearer token to headers.
 *
 * @param tokenData
 * @param headers
 * @returns {*}
 */
export const addTokenHeader = (tokenData, headers) => {
    const newHeaders = headers;

    if (tokenData && tokenData.token) {
        newHeaders[AUTH_HEADER] = `${AUTH_BEARER_PREFIX}${tokenData.token}`;
    }

    return newHeaders;
};

/**
 * Extract the token from the headers and store it.
 *
 * @param headers
 * @returns {null|*}
 */
export const updateTokenFromHeaders = (headers) => {
    const header = headers.get(AUTH_HEADER);

    if (header && header.indexOf(AUTH_BEARER_PREFIX) === 0) {
        const token = header.replace(AUTH_BEARER_PREFIX, '');
        const existingTokenData = getToken();

        // only update if it has changed
        if (!existingTokenData || token !== existingTokenData.token) {
            return setToken(token);
        }
    }

    return null;
};

/**
 * Refresh the token. It sends original token and receives an updated token in the headers.
 *
 * @param tokenData
 * @returns {Promise<null>}
 */
export const refreshToken = async (tokenData) => {
    const headers = addTokenHeader(tokenData, {});

    const response = await fetch(`${process.env.REACT_APP_SERVER_URL_BASE}/auth/refresh`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ withoutSession: true }),
        credentials: 'omit',
    });

    const data = await response.json();

    if (data.status) {
        return updateTokenFromHeaders(response.headers);
    }

    throw new Error('Unable to refresh token');
};

/**
 * Check if the token has a scope.
 *
 * @param {string} scope
 *
 * @returns {boolean}
 */
export const hasScope = (scope) => {
    const tokenData = getToken();

    // check for valid token
    if (!tokenData || hasTokenExpired(tokenData)) {
        return false;
    }

    const decoded = jwtDecode(tokenData.token);
    return Array.isArray(decoded?.scopes) && decoded.scopes.includes(scope);
};

export function loginUser(email, password, scopes = []) {
    return async (dispatch) => {
        dispatch(loginRequest());

        return fetch(`${process.env.REACT_APP_SERVER_URL_BASE}/auth/login`, {
            credentials: 'omit',
            method: 'POST',
            body: JSON.stringify({
                scope: scopes, email, password, withoutSession: true, domain: 'wtbox.com',
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(async (response) => {
            const data = await response.json();

            dispatch(loginUserSuccess(data));

            // handle a redirect for logging into wrong site
            if (data.status) {
                setToken(data.token);

                if (!hasScope(SCOPE_MARKER)) {
                    setToken(null);
                    throw new LoginError('User does not have access to Marking');
                }

                // error with a message
            } else if (data.message) {
                throw new LoginError(data.message);

                // specific html error message
            } else if (data.htmlMessage) {
                throw new LoginError(data.htmlMessage, 'html', data.link);
            }

            return data;
        }).catch((error) => {
            dispatch(loginError(error.message));
        });
    };
}

export function loginRequest() {
    return {
        type: actionTypes.LOGIN_REQUEST,
    };
}

export function loginSuccess(data) {
    return {
        type: actionTypes.LOGIN_SUCCESS,
        data,
    };
}

export function loginUserSuccess(data) {
    return {
        type: actionTypes.LOGIN_USER_SUCCESS,
        data,
    };
}

export function loginError(message) {
    return {
        type: actionTypes.LOGIN_ERROR,
        message,
    };
}

export function logout() {
    return {
        type: actionTypes.LOGOUT,
    };
}
