import React from 'react';
import { defaultProps, getContainerClass, getLabel } from '../config';

const LabelField = ({ containerClass, fieldClass, label, labelClass, name, ...otherProps }) => (
    <div className={getContainerClass(containerClass, label)}>
        {getLabel(label, name, containerClass, fieldClass, labelClass)}
    </div>
);

// LabelField.defaultProps = Object.assign({}, FormField.defaultProps, {
//     fieldClass: 'label-field'
// });

LabelField.defaultProps = {
    ...defaultProps,
    fieldClass: 'label-field',
};

export default LabelField;