import React from "react";
import PropTypes from "prop-types";

//components
import Table from "../../components/Table/Table";
import { MODE_MARKING, MODE_REVIEWING } from "../includes/marking-flow/constants";

const AssessmentList = ({
    assessments,
    onStartAssessment,
    onViewSubmissions
}) => (
    <div className="assessments-list">
        {assessments && assessments.length && <div className="assessments-list__table">
            <Table
                searchable={true}
                defaultSearchField="Title"
                fields={{
                    school: {
                        label: 'School',
                        sort: true,
                        value: o => o.school.Name,
                    },
                    title: {
                        label: 'Title',
                        sort: true,
                        value: o => o.staffName,
                    },
                    numberOfAnswers: {
                        label: 'Answers',
                        sort: true,
                        value: o => o.numMarkableAnswers,
                    },
                    numberOfMarkedAnswers: {
                        label: 'Marked',
                        sort: true,
                        value: o => o.numMarkedAnswers,
                    },
                    numberOfReviewedAnswers: {
                        label: 'Reviewed',
                        sort: true,
                        value: o => o.numReviewedAnswers,
                    },
                    actions: {
                        label: '',
                        value: (o) => {
                            const onStartMarking = e => {
                                onStartAssessment(e, { assessment: o, markingMode: MODE_MARKING });
                            }

                            const onStartReviewing = e => {
                                onStartAssessment(e, { assessment: o, markingMode: MODE_REVIEWING });
                            }

                            return (
                                <div style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    gap: "5px",
                                }}>
                                    <button onClick={onStartMarking}>Mark</button>
                                    <button onClick={onStartReviewing}>Review</button>
                                    <i
                                        className="material-icons icon icon--large icon--rounded icon--primary icon--pointer"
                                        title="View submissions"
                                        onClick={(e) => onViewSubmissions(e, o)}
                                    >
                                        search
                                    </i>
                                </div>
                            );
                        }
                    }
                }}
                list={assessments}/>
        </div>}

        {!(assessments && assessments.length) && <p>No assessments could be found.</p>}
    </div>
);

AssessmentList.propTypes = {
    assessments: PropTypes.array.isRequired,
    onStartAssessment: PropTypes.func.isRequired,
    onViewSubmissions: PropTypes.func.isRequired,
};

export default AssessmentList;