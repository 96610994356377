import { gql } from '@apollo/client';
import { MarkingQueryFragment } from "./MarkingQueryFragment";

export const DIAGNOSTIC_MARKINGS = gql`
    query diagnosticMarkings ($assessmentId: ID!) {
        diagnosticMarkings (assessmentId: $assessmentId) {
            id
            yearLevel
            yearLevelLabel
            questionNumber
            questionLetter
            status
            marker {
                id
                firstName
                surname
            }
            reviewer {
                id
                firstName
                surname
            }
            student {
                id
                FirstName
                Surname
            }
            piece {
                id
                IsMarkable
                IsReviewable
            }
        }
    }
`;

export const GET_NEXT_MARKING = gql`
    query getNextMarking($assessmentId: ID!, $markingMode: String, $markerId: ID) {
        getNextMarking(assessmentId: $assessmentId, markingMode: $markingMode, markerId: $markerId) {
            ...MarkingQueryFragment
        }
    }
    ${MarkingQueryFragment}
`;

export const GET_PREV_MARKING = gql`
    query getPrevMarking($assessmentId: ID!, $currentMarkingId: ID!, $markingMode: String, $markerId: ID) {
        getPrevMarking(
            assessmentId: $assessmentId, 
            currentMarkingId: $currentMarkingId,
            markingMode: $markingMode, 
            markerId: $markerId
        ) {
            ...MarkingQueryFragment
        }
    }
    ${MarkingQueryFragment}
`;

export default {
    DIAGNOSTIC_MARKINGS,
    GET_NEXT_MARKING,
    GET_PREV_MARKING,
}
