import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { logout } from '../../actions/auth';
import * as authService from '../../actions/auth';

export const configureClient = (store) => {
    /**
     * Adds bearer token to apollo requests.
     *
     * @type {ApolloLink}
     */
    const authMiddleware = setContext(async () => {
        let token = authService.getToken();

        if (token && token.token) {
            if (!authService.hasTokenExpired(token)) {
                // refresh token if it needs refreshing
                if (authService.shouldTokenRefresh(token)) {
                    try {
                        token = await authService.refreshToken(token);
                    } catch (e) {
                        // do something
                    }
                }

                // add the authorization to the headers
                return {
                    headers: authService.addTokenHeader(token, {}),
                    credentials: 'omit',
                };
            }
        }

        return {
            credentials: 'omit',
        };
        // if no token we include credentials so session can be used, if there is a valid
        // session a token will be returned in the headers return withCredentials;
    });

    const authAfterware = new ApolloLink((operation, forward) => (
        forward(operation).map((response) => {
            const { response: { headers } } = operation.getContext();
            if (headers) {
                authService.updateTokenFromHeaders(headers);
            }

            return response;
        })
    ));

    const authError = onError((errorHandler) => {
        const networkError = { ...errorHandler.networkError };

        if (networkError && networkError.statusCode === 401) {
            store.dispatch(logout());

            return;
            // capture response information for network errors
        }

        if (networkError && networkError.response) {
            let body = networkError.bodyText || '';

            // limit length, max size of sentry logging request is 100kB
            if (body.length >= 5000) {
                body = body.substring(0, 5000);
            }

            return;
        }

        const graphQLErrors = { ...errorHandler.graphQLErrors };

        if (graphQLErrors && graphQLErrors.length) {
            for (let i = 0; i < graphQLErrors.length; i++) {
                // check for auth error
                if (graphQLErrors[i].code === 403 || graphQLErrors[i].code === 401) {
                    store.dispatch(logout());
                }
            }
        }
    });

    const httpLink = new HttpLink({
        uri: `${process.env.REACT_APP_SERVER_URL_BASE}/graphql`,
    });

    return new ApolloClient({
        link: ApolloLink.from([
            authMiddleware,
            authAfterware,
            authError,
            httpLink,
        ]),
        cache: new InMemoryCache({
            // Schema for explicitly resolving interface types.
            possibleTypes: {
                WrittenAnswer: ['LongWrittenAnswer', 'ShortWrittenAnswer'],
            },
        }),
    });
};

export default configureClient;
