import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setOffline } from "../actions/global";

const ConnectionChecker = ({ children }) => {
    const dispatch = useDispatch();

    const offline = () => {
        dispatch(setOffline(true));
    }

    const online = () => {
        dispatch(setOffline(false));
    }

    useEffect(
        () => {
            window.addEventListener('offline', offline);
            window.addEventListener('online', online);

            //check on first load
            if (navigator.onLine) {
                online();
            } else {
                offline();
            }

            return () => {
                window.removeEventListener('offline', offline);
                window.removeEventListener('online', online);
            };
        },
        []
    );

    return (
        children
    );
}

export default ConnectionChecker;