export default class User {
    constructor(username, scopes) {
        this.username = username;
        this.scopes = scopes || [];
    }

    hasScope(scope) {
        return this.scopes.includes(scope);
    }

    getUsername() {
        return this.username;
    }

    getScopes() {
        return this.scopes;
    }
}
