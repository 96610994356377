import { gql } from "@apollo/client";
import FullSubmissionFragment from "./FullSubmissionFragment";
import FullParagraphFragment from "./FullParagraphFragment";
import FullSentenceFragment from "./FullSentenceFragment";

export const MarkingQueryFragment = gql`
    fragment MarkingQueryFragment on WrittenAnswer {
        id
        status
        question {
            id
            instructions
        }
        marker {
            id
            firstName
            surname
        }
        reviewer {
            id
            firstName
            surname
        }
        student {
            id
            FirstName
            Surname
        }
        submission: piece {
            ...FullSubmissionFragment
            paragraphs {
                ...FullParagraphFragment
                sentences {
                    ...FullSentenceFragment
                }
            }
        }
    }
    ${FullSubmissionFragment}
    ${FullParagraphFragment}
    ${FullSentenceFragment}
`;

export default { MarkingQueryFragment };