import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from '../Form/Fields/TextField';

const TableSearchHeader = ({
    cellClassName = '',
    containerClassName = '',
    fields = {},
    onSearch = () => {},
    rowClassName = '',
    searchedFields = {},
    sortDirection = '',
    sortField = '',
}) => {
    const timeout = null;

    useEffect(
        () => {
            return clearTimeout(timeout);
        },
        []
    );

    /**
     * Render icons for sorting the column.
     *
     * @param {String} fieldName
     * @returns {*}
     */
    const renderSort = (fieldName) => {
        if (fieldName === sortField) {
            return <div className="md-icon-separator">
                <i className="md-icon material-icons md-collapser md-collapser--flipped icon--pointer">{sortDirection === 'asc' ? 'arrow_upward' : 'arrow_downward'}</i>
                <span className="md-icon-text">{fields[fieldName].label}</span>
            </div>;
        } else {
            return <div className="md-icon-separator">
                <i className="md-icon material-icons md-collapser md-collapser--flipped icon--pointer">sort</i>
                <span className="md-icon-text">{fields[fieldName].label}</span>
            </div>;
        }
    }

    return (
        <thead className={containerClassName}>
            <tr className={rowClassName}>
                {Object.keys(fields).map((fieldName) => (
                    <th
                        className={cellClassName + (fields[fieldName].className ? ' ' + fields[fieldName].className : '')}
                        scope="col"
                        key={fieldName}
                    >
                        {fieldName !== 'actions' && 'value' in fields[fieldName] && (
                            <div style={{position: 'relative'}}>
                                <TextField
                                    containerClass="table-cell__search-text-area-container"
                                    onChange={(event) => onSearch(fieldName, event.target.value)}
                                    value={searchedFields[fieldName]}
                                />
                                <i className="material-icons search-close" onClick={() => onSearch(fieldName, '')}>close</i>
                            </div>
                        )}
                        {fieldName === 'actions' && (
                            <i
                                className="material-icons icon icon--large icon--rounded icon--primary icon--pointer"
                                onClick={() => onSearch()}
                                style={{ float: 'right' }}
                            >
                                close
                            </i>
                        )}
                    </th>
                ))}
            </tr>
        </thead>
    );
}

TableSearchHeader.propTypes = {
    cellClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    fields: PropTypes.object,
    onSearch: PropTypes.func,
    rowClassName: PropTypes.string,
    searchedFields: PropTypes.object,
    sortDirection: PropTypes.string,
    sortField: PropTypes.string,
};

export default TableSearchHeader;